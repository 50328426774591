import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
//组件之间进行数据通信 added by 葛明 2020-9-16
export class CommunicationService {

  // Observable string sources
  fatherSubject = new Subject<any>();
  sonSubject = new Subject<any>();
  reflash=new Subject<any>();  
  contentSubject = new Subject<any>(); //内容观察者

  // Service message commands
  sentToSon(message: any) {
    this.fatherSubject.next(message);
  }

  sentToFather(message: any) {
    this.sonSubject.next(message);
  }

  sentToContent(message: any) {
    this.contentSubject.next(message);
  }

  reflashContent(message: any){
    this.reflash.next(message);
  }

  //通用组件父主题
  fatherGCSubject = new Subject<any>();
  //发送给通用子组件
  sentToGCSon(message: any) {
    this.fatherGCSubject.next(message);
  }

  //查询下拉列表父值主题
  fatherValueNameSubject = new Subject<any>();
  //发送给查询-子级下拉列表
  sentToSearchComboboxSon(message: any) {
    this.fatherValueNameSubject.next(message);
  }

}
