import { NbMenuItem } from '@nebular/theme';
// 安邦菜单
export const menu_items_anbang: NbMenuItem[] = [
  {
    title: '控制台',
    icon: 'home-outline',
    link: '/pages/dashboard',
    home: true,
  },
  {
    title: '功能模块',
    group: true,
  },
  {
    title: '大屏管理',
    icon: 'monitor-outline',
    children: [

    ],
  },
  //大屏数据管理
  {
    title: '大屏数据管理',
    icon: 'monitor-outline',
    children: [
      {
        title: '集团经营-各省销售额',
        link: '/pages/desktopTemp/deskTempProvincialSales',
      },
      {
        title: '集团经营-历年经营',
        link: '/pages/desktopTemp/deskTempRevenueOverYears',
      },
      {
        title: '集团经营-当年预算',
        link: '/pages/desktopTemp/deskTempCurrentYearBudget',
      },
      {
        title: '集团运营-当日任务',
        link: '/pages/desktopTemp/deskTempCurrentDayTask',
      },
      {
        title: '集团运营-当月任务',
        link: '/pages/desktopTemp/deskTempCurrentMonthTask',
      },
      {
        title: '集团运营-当年任务',
        link: '/pages/desktopTemp/deskTempCurrentYearTask',
      },
      {
        title: '集团运营-左半边',
        link: '/pages/desktopTemp/deskTempOperationLeft',
      },
      {
        title: '集团运营-地图',
        link: '/pages/desktopTemp/deskTempOperationMap',
      },
      {
        title: '集团运营-清分金额',
        link: '/pages/desktopTemp/deskTempClearAmount',
      },
      {
        title: '集团经营-其他',
        link: '/pages/desktopTemp/deskTempManageOthers',
      },
      {
        title: '嘉兴大屏-执行情况',
        link: '/pages/desktopTemp/deskTempJiaxingTask',
      },
      {
        title: '嘉兴大屏-其他',
        link: '/pages/desktopTemp/deskTempJiaxing',
      },
      {
        title: '集团安全-事故',
        link: '/pages/desktopTemp/deskTempAccidentOverMonths'
      },
      {
        title: '集团安全-运钞车',
        link: '/pages/desktopTemp/deskTempCashTrunkOverMonths'
      },
      {
        title: '集团安全-城市概况',
        link: '/pages/desktopTemp/deskTempSecInfoOverCities'
      },
      {
        title: '集团安全-其他',
        link: '/pages/desktopTemp/deskTempSecInfoOthers'
      },
      {
        title: 'IPO指标-股权结构',
        link: '/pages/desktopTemp/deskTempIpoStockRight'
      },
      {
        title: 'IPO指标-募集资金运用',
        link: '/pages/desktopTemp/deskTempIpoCapital'
      },
      {
        title: 'IPO指标-财务比率',
        link: '/pages/desktopTemp/deskTempIpoFinance'
      },
      {
        title: 'IPO指标-各类财务指标',
        link: '/pages/desktopTemp/deskTempIpoIndex'
      },
      {
        title: 'IPO指标-各地市收入',
        link: '/pages/desktopTemp/deskTempIpoCity'
      },
      {
        title: '业务态势',
        link: '/pages/desktopTemp/deskTempSituation'
      }

    ],
  },

 //系统管理
  {
    title: '系统管理',
    icon: 'settings-outline',
    children: [
      {
        title: '字典管理',
        link: '/pages/system/dictionary',
      },

      {
        title: '模块管理',
        link: '/pages/system/module',
      },

      {
        title: '模块功能管理',
        link: '/pages/system/moduleFunc',
      },

      {
        title: '角色管理',
        link: '/pages/system/role',
      },

      {
        title: '用户管理',
        link: '/pages/system/user',
      }
    ],
  },
// 日志管理
  {
    title: '日志管理',
    icon: 'clipboard-outline',
    children: [
      {
        title: '登录日志',
        link: '/pages/logs/loginLog',
      },
      {
        title: '操作日志',
        link: '/pages/logs/operateLog',
      },
    ],
  },
  // 人事管理
  {
    title: '人事管理',
    icon: 'people-outline',
    children: [
      {
        title: '员工信息',
        link: '/pages/oa/oaUser',
      },
      {
        title: '转正申请',
        link: '/pages/oa/oaApplyBeFull',
      },
      {
        title: '学历技能备案',
        link: '/pages/oa/oaApplyLearning',
      },
      {
        title: '学历奖励申请',
        link: '/pages/oa/oaApplyLearningReward',
      },
      {
        title: '技能职称津贴申请',
        link: '/pages/oa/oaApplySubsidy',
      },
      {
        title: '加班申请',
        link: '/pages/oa/oaApplyOvertime',
      },
      {
        title: '请假申请',
        link: '/pages/oa/oaApplyVacation',
      },
      {
        title: '调动申请',
        link: '/pages/oa/oaApplyRelieving',
      },
      {
        title: '培训申请',
        link: '/pages/oa/oaApplyTraining',
      },
      {
        title: '销假申请',
        link: '/pages/oa/oaApplyCancelVacation',
      },
      {
        title: '离职申请',
        link: '/pages/oa/oaApplyLeave',
      },
      {
        title: '非正式员工',
        link: '/pages/oa/oaUserInformal',
      },
    ],
  },
 
 // 绩效管理
  {
    title: '绩效管理',
    icon: 'layers-outline',
    children: [
      {
        title: '业务-区域管理',
        link: '/pages/kpi/kpi-region-manage',
      }, {
        title: '业务-网点管理',
        link: '/pages/kpi/kpi-network-manage',
      }, {
        title: '业务-级别管理',
        link: '/pages/kpi/kpi-level-manage',
      }, {
        title: '业务-线路管理',
        link: '/pages/kpi/kpi-line-manage',
      }, {
        title: '业务-中队管理',
        link: '/pages/kpi/kpi-detach-manage',
      }, {
        title: '业务-员工管理',
        link: '/pages/kpi/kpi-personnel-manage',
      }, {
        title: '绩效-员工',
        link: '/pages/kpi/employee',
      }, {
        title: '绩效-员工行政',
        link: '/pages/kpi/employee-admin',
      }, {
        title: '绩效-员工固定任务',
        link: '/pages/kpi/employee-stable',
      }, {
        title: '绩效-员工临时任务',
        link: '/pages/kpi/employee-temporary',
      }, {
        title: '绩效-员工ATM任务',
        link: '/pages/kpi/employee-atm',
      }, {
        title: '绩效-员工其他任务',
        link: '/pages/kpi/employee-others',
      }, {
        title: '绩效-员工特殊任务',
        link: '/pages/kpi/employee-special',
      },
    ],
  },
  // 服务流程管理
  {
    title: '服务运营管理',
    icon: 'phone-outline',
    children: [
      {
        title: '小程序用户管理',
        link: '/pages/serviceOperation/userManagement',
      },
      {
        title: '网点人员管理',
        link: '/pages/serviceOperation/websiteStaff',
      },
      {
        title: '流程管理',
        link: '/pages/serviceOperation/processManagement',
      },
      {
        title: '服务计划管理',
        link: '/pages/serviceOperation/servicePlan',
      },
      {
        title: '服务管理',
        link: '/pages/serviceOperation/bizService',
      },
      {
        title: '任务管理',
        link: '/pages/serviceOperation/bizTask',
      },
      {
        title: '任务执行日志',
        link: '/pages/serviceOperation/taskLog',
      },
    ],
  },
  // 车辆管理
  {
    title: '车辆管理',
    icon: 'car-outline',
    children: [
      {
        title: '车辆信息管理',
        link: '/pages/vehicle/vehicleCarInfo',
      },
      {
        title: '车载设备管理',
        link: '/pages/hik7200/carDevice',
      },
      {
        title: '车载告警管理',
        link: '/pages/hik7200/carAlarm',
      },
      {
        title: 'GPS管理',
        link: '/pages/hik7200/gps',
      },
      {
        title: '车载通知管理',
        link: '/pages/hik7200/carState',
      }
    ]
  },
  // 枪支管理
  {
    title: '枪支管理',
    icon: 'scissors-outline',
    children: [
      {
        title: '枪支信息管理',
        link: '/pages/device/gunGunInfo',
      },
      {
        title: '枪弹库管理',
        link: '/pages/device/gunWarehouse',
      },
      {
        title: '弹药管理',
        link: '/pages/device/gunBulletInfo',
      },
      {
        title: '弹药出入库管理',
        link: '/pages/device/gunBulletLog',
      },
      {
        title: '枪支运营管理',
        // icon: 'brush-outline',
        link: '/pages/device/gun',

      },
      {
        title: '枪支领用管理',
        // icon: 'brush-outline',
        link: '/pages/device/gunUseInfo',

      },
      {
        title: '枪支告警管理',
        link: '/pages/device/gunAlarm',
      },
      {
        title: '枪支GPS管理',
        link: '/pages/device/gunGps',
      },
    ],
  },
  // 库房管理
  {
    title: '库房管理',
    icon: 'calendar-outline',
    children: [
      {
        title: '箱子信息管理',
        link: '/pages/warehouse/kf-boxinfo',
      },
      {
        title: '库存管理',
        link: '/pages/warehouse/kf-boxstock',
      },
      {
        title: '入库交接管理',
        link: '/pages/warehouse/kf-instore',
      },
      {
        title: '内部交接管理',
        link: '/pages/warehouse/kf-intertask',
      },
      {
        title: '请求库房信息管理',
        link: '/pages/warehouse/kf-taskrequest',
      },
      {
        title: '下发数据管理',
        link: '/pages/warehouse/kf-boxsenddata',
      },
      {
        title: '任务箱子信息监控管理',
        link: '/pages/warehouse/kf-taskboxmonitored',
      },
    ],
  },
  // 交接管理
  {
    title: '交接管理',
    icon: 'calendar-outline',
    children: [
      {
        title: '网点管理',
        link: '/pages/handover/jjBankOutLetInfo',
      },
      {
        title: '线路管理',
        link: '/pages/handover/jjRouteinfo',
      },
      // {
      //   title: '线路站点管理',
      //   link: '/pages/handover/jjRouteoutletinfo',
      // },
      {
        title: '车队管理',
        link: '/pages/handover/jjTeaminfo',
      },
      {
        title: '押运车管理',
        link: '/pages/handover/jjVehicleinfo',
      },
      {
        title: '工作组管理',
        link: '/pages/handover/jjWorkgroupinfo',
      },
      {
        title: '用户信息管理',
        link: '/pages/handover/jjUserinfo',
      },
      {
        title: '角色信息管理',
        link: '/pages/handover/jjRoleinfo',
      },
      {
        title: '任务人员管理',
        link: '/pages/handover/jjTaskUser',
      },
      {
        title: '请求交接信息管理',
        link: '/pages/handover/jjTaskrequest',
      },
      {
        title: '任务管理',
        link: '/pages/handover/jjTask',
      },
      {
        title: '子任务管理',
        link: '/pages/handover/jjTaskchildren',
      },
    ],
  },
 // 业务管理
  {
    title: '业务管理',
    icon: 'briefcase-outline',
    children: [
      {
        title: '合同信息管理',
        link: '/pages/contract/conContract',
      },
      {
        title: '结算单管理',
        link: '/pages/contract/conBill',
      },
      {
        title: '银行网点管理',
        link: '/pages/contract/conBranchBank',
      },
      {
        title: '上门服务点管理',
        link: '/pages/contract/conBranchServer',
      },
      {
        title: '自助设备管理',
        link: '/pages/contract/conBranchAtm',
      },
      {
        title: '备勤点管理',
        link: '/pages/contract/ConBranchPrepared',
      },
      {
        title: '合同产品关联的网点',
        link: '/pages/contract/MapProBranch',
      },
      {
        title: '公司信息',
        link: '/pages/contract/ConConpany',
      },
      {
        title: '签约客户',
        link: '/pages/contract/ConCustomer',
      },
      {
        title: '客户通称',
        link: '/pages/contract/ConCustomerCategory',
      },
      {
        title: '产品',
        link: '/pages/contract/ConProduct',
      },
      {
        title: '产品分类',
        link: '/pages/contract/ConProductCategory',
      },
      {
        title: '清分中心',
        link: '/pages/contract/ConBranchDivide',
      }
    ]
  },
  // IPO管理
  {
    title: 'IPO管理',
    icon: 'folder-outline',
    children: [
      {
        title: '房屋租赁',
        link: '/pages/ipo/ipoHouseLease',
      },
      {
        title: '房产转租',
        link: '/pages/ipo/ipoHouseSublease',
      },
      {
        title: '承租经营性资产',
        link: '/pages/ipo/ipoLeaseBusAssets',
      },
      {
        title: '承租非经营性资产',
        link: '/pages/ipo/ipoLeaseNonBusAssets',
      },
      {
        title: '固定资产',
        link: '/pages/ipo/ipoFixedAssets',
      },
      {
        title: '行政处罚',
        link: '/pages/ipo/ipo-punish',
      },
      {
        title: '销售与服务合同',
        link: '/pages/ipo/ipoXsFwContract',
      },
      {
        title: '资质',
        link: '/pages/ipo/ipoQualification',
      },
      {
        title: '商标',
        link: '/pages/ipo/ipoBrand',
      },
      {
        title: '域名',
        link: '/pages/ipo/ipoDomain',
      },
      {
        title: '专利',
        link: '/pages/ipo/ipoPatent',
      },
      {
        title: '软著',
        link: '/pages/ipo/ipoSoftCopyright',
      },
      {
        title: '借款合同',
        link: '/pages/ipo/ipoLoanContract',
      },
      {
        title: '资产财务',
        link: '/pages/ipo/ipoAssetFinance',
      },
      {
        title: '组织人事',
        link: '/pages/ipo/ipoOrganizationPersonnel',
      },
    ]
  },

]
// 拱宸桥菜单
export const menu_items_gcq: NbMenuItem[] = [
  {
    title: '控制台',
    icon: 'home-outline',
    link: '/pages/dashboard',
    home: true,
  },
  {
    title: '功能模块',
    group: true,
  },

   //系统管理
   {
    title: '系统管理',
    icon: 'settings-outline',
    children: [
      {
        title: '字典管理',
        icon:'book-outline',
        link: '/pages/system/dictionary',
      },

      {
        title: '模块管理',
        icon:'cube-outline',
        link: '/pages/system/module',
      },

      {
        title: '模块功能管理',
        icon:'credit-card-outline',
        link: '/pages/system/moduleFunc',
      },

      {
        title: '角色管理',
        icon:'person-outline',
        link: '/pages/system/role',
      },

      {
        title: '用户管理',
        icon:'people-outline',
        link: '/pages/system/user',
      }
    ],
  },
// 日志管理
  {
    title: '日志管理',
    icon: 'clipboard-outline',
    children: [
      {
        title: '登录日志',
        icon:'file-outline',
        link: '/pages/logs/loginLog',
      },
      {
        title: '操作日志',
        icon:'file-text-outline',
        link: '/pages/logs/operateLog',
      },
    ],
  },
  //门禁管理
  {
    title: '门禁管理',
    icon: 'bell-outline',
    children: [
      {
        title: '门禁列表',
        link: '/pages/door-manage/door',
      },
    ],
  },
   //管理人员库
   {
    title: '管理人员库',
    icon: 'people-outline',
    children:[
      {
        title: '街道基础信息',
        icon:'menu-arrow-outline',
        children: [
          {
            title: '机构管理',
            link: '/pages/empl/department',
          },
          {
            title: '机构人员管理',
            link: '/pages/empl/employee',
          },
        ]
      },
      {
        title: '老人基本信息',
        icon:'person-remove-outline',
        link: '/pages/batchData/lrjbxx',
      },
      {
        title: '人员户籍信息',
        icon:'npm-outline',
        link: '/pages/batchData/ryhjxx',
      },
      {
        title: '居民基本信息',
        icon:'person-outline',
        link: '/pages/batchData/jmjbxx',
      },
      {
        title: '残疾人员基本信息',
        icon:'heart',
        link: '/pages/batchData/cjryjbxx',
      },
      {
        title: '微消人员动态信息',
        icon:'phone-call-outline',
        link: '/pages/batchData/wxrydtxx',
      },
      {
        title: '街道党建管理',
        icon:'flag-outline',
        children: [
          {
            title: '党员管理',
            link: '/pages/party/party-member',
          },
          {
            title: '党组织管理',
            link: '/pages/party/party-org',
          },
          {
            title: '党建管理',
            link: '/pages/party/party-building',
          },
          {
            title: '党员发展管理',
            link: '/pages/party/party-develop',
          },
        ],
      },
      {
        title: '残疾人员信息',
        icon:'moon-outline',
        link: '/pages/interfaceData/cjryxx',
      },
      {
        title: '人员基础信息',
        icon:'pantone-outline',
        link: '/pages/interfaceData/ryjcxx',
      },
      {
        title: '物业人员信息',
        icon:'radio-outline',
        link: '/pages/peopleManage/wyryxx',
      },
      {
        title: '志愿者信息',
        icon:'sun-outline',
        link: '/pages/peopleManage/zyzxx',
      },
      {
        title: '微消站人员信息',
        icon:'shield-outline',
        link: '/pages/peopleManage/wxzryxx',
      },
      {
        title: '网格员信息',
        icon:'globe-outline',
        link: '/pages/peopleManage/wgyxx',
      },
      {
        title: '值班管理',
        icon:'volume-up-outline',
        link: '',
        children:[
          {
            title: '防疫数据处置值班表',
            link: '/pages/peopleManage/fysjczzbb',
          },
          {
            title: '防控数据值班表',
            link: '/pages/peopleManage/fysjzbb',
          },
          {
            title: '防疫人员',
            link: '/pages/peopleManage/fyry',
          },
          {
            title: '110联动值班',
            link: '/pages/peopleManage/ldzb',
          },
          {
            title: '日常值班',
            link: '/pages/peopleManage/sqpszb',
          },
          {
            title: '综合执法值班',
            link: '/pages/peopleManage/zhzfzb',
          },
        ]
      },
      {
        title: '街道工作人员信息',
        icon:'pantone-outline',
        link: '/pages/peopleManage/jdgzryxx',
      },
      {
        title: '应急队伍信息',
        icon:'bulb',
        link: '/pages/peopleManage/yjdwxx',
      }
    ]
  },
  //管理对象库
  {
    title: '管理对象库',
    icon: 'inbox-outline',
    children:[
      {
        title: '商家信息',
        icon:'award-outline',
        link: '/pages/batchData/sjxx',
      },
      {
        title: '楼栋基本信息',
        icon:'bar-chart-2-outline',
        link: '/pages/batchData/ldjbxx',
      },
      {
        title: '智治系统_企业基本信息',
        icon:'bookmark-outline',
        link: '/pages/batchData/zzxtqyjbxx',
      },
      {
        title: '加油站信息',
        icon:'droplet',
        link: '/pages/batchData/jyzxx',
      },
      {
        title: '安全生产企业信息',
        icon:'shield',
        link: '/pages/batchData/aqsccxqyxx',
      },
      {
        title: '网格基本信息',
        icon:'keypad',
        link: '/pages/batchData/wgjbxx',
      },
      {
        title: '社区基本信息',
        icon:'globe',
        link: '/pages/batchData/sqjbxx',
      },
      {
        title: '微消站_人员信息',
        icon:'person',
        link: '/pages/batchData/wxzryxx',
      },
      {
        title: '微消站_设施信息',
        icon:'scissors',
        link: '/pages/batchData/wxzssxx',
      },
      {
        title: '微消站信息',
        icon:'speaker',
        link: '/pages/batchData/wxzxx',
      },
      {
        title: '杭州市统一地址库',
       icon:'globe-2',
        link: '/pages/batchData/hzstydzkxx',
      },
      {
        title: '安全生产不良记录黑名单',
        icon:'book-open',
        link: '/pages/batchData/aqscbljlhmdxx',
      },
      {
        title: '市场主体基本信息',
        icon:'globe',
        link: '/pages/objectManage/MarketMajorInfo',
      },
      {
        title: '视频管理',
        icon:'video',
        children: [
          {
            title: '摄像头管理',
            link: '/pages/video/camera',
          },
          {
            title: '视频实时播放',
            link: '/pages/video/real-time-playing',
          },
          {
            title: '视频历史回放',
            link: '/pages/video/playback',
          },
    
        ],
      },
      {
        title: '基础信息管理',
        icon:'bookmark',
        children: [
          {
            title: '海豚平台管理',
            link: '/pages/base-info/dolphin',
          },
        ],
      },
      {
        title: '店铺信息',
        icon:'shopping-bag',
        link: '/pages/objectManage/dpxx',
      },
      {
        title: '公共服务',
        icon:'smiling-face',
        children: [
          {
            title: '低保在册人员',
            link: '/pages/objectManage/dbzcry',
          },
          {
            title: '低保在册户数',
            link: '/pages/objectManage/dbzchs',
          },
          {
            title: '低保边缘户在册人员',
            link: '/pages/objectManage/dbbyhzcry',
          },
          {
            title: '低保边缘户在册户数',
            link: '/pages/objectManage/dbbyhzchs',
          },
          {
            title: '其他老人',
            link: '/pages/objectManage/qtlr',
          },
          {
            title: '养老护理补贴享受名单',
            link: '/pages/objectManage/ylhlbtxsmd',
          },
          {
            title: '津贴比对',
            link: '/pages/objectManage/subsidycompare',
          },
          {
            title: '当月到龄高龄老人',
            link: '/pages/objectManage/dydlgllr',
          },
          {
            title: '生活补贴资金发放明细',
            link: '/pages/objectManage/shbtzjffmx',
          },
          {
            title: '生活补贴资金发放汇总',
            link: '/pages/objectManage/shbtzjffhz',
          },
          {
            title: '电子津贴补贴享受名单',
            link: '/pages/objectManage/dzjtbtxsmd',
          },
          {
            title: '老人街道汇总',
            link: '/pages/objectManage/lrjdhz',
          },
          {
            title: '重残护理补贴发放明细',
            link: '/pages/objectManage/zchlbtffmx',
          },
          {
            title: '重残护理补贴发放汇总',
            link: '/pages/objectManage/zchlbtffhz',
          },
          {
            title: '高龄普惠补贴享受名单',
            link: '/pages/objectManage/glhpbtxsmd',
          }
        ],
      },
      {
        title: '辖区学校信息',
        icon:'flag',
        link: '/pages/objectManage/xqxxxx',
      },
      {
        title: '写字楼信息',
        icon:'home-outline',
        link: '/pages/objectManage/officeBuilding',
      },
      {
        title: '小区管理',
        icon:'home-outline',
        link: '/pages/objectManage/community',
      },
    ]
  },
  // 物资库
  {
    title: '物资库',
    icon: 'car-outline',
    children:[
      {
        title: '应急（避灾）管理',
        icon: 'info',
        children: [
          {
            title: '避灾点管理',
            link: '/pages/emergencydisaster/disaster',
          }, 
          {
            title: '避灾点物资管理',
            link: '/pages/emergencydisaster/disasterMaterials',
          }, 
        ],
      },
      {
        title: '应急物资仓库信息',
        icon: 'bulb-outline',
        link: '/pages/batchData/yjwzckxx',
      },
      {
        title: '应急（消防）仓库管理',
        icon: 'navigation-2-outline',
        children: [
          {
            title: '消防仓库管理',
            link: '/pages/emergencyfire/stockFire',
          }, 
          {
            title: '消防库存管理',
            link: '/pages/emergencyfire/warehouseFire',
          }, 
        ],
      },
      {
        title: '应急（防汛防台）仓库管理',
        icon: 'flash-outline',
        children: [
          {
            title: '防汛仓库管理',
            link: '/pages/emergencyflood/stockFlood',
          }, 
          {
            title: '防汛库存管理',
            link: '/pages/emergencyflood/warehouseFlood',
          }, 
        ],
      },
      {
        title: '仓库管理',
        icon: 'bulb-outline',
        link: '/pages/gcqWarehouse/warehouseManage',
      },
      {
        title: '调拨管理',
        icon: 'bulb-outline',
        link: '/pages/gcqWarehouse/transferManagement',
      },
      {
        title: '街道库存管理',
        icon: 'flash-outline',
        link: '/pages/gcqWarehouse/stockManage',
      },
      {
        title: '出库管理',
        icon: 'bulb-outline',
        link: '/pages/gcqWarehouse/outputManage',
      },
      {
        title: '入库管理',
        icon: 'bulb-outline',
        link: '/pages/gcqWarehouse/inputManage',
      },
      // hhs 2023-3-30
      {
        title: '库存告警配置管理',
        icon: 'bulb-outline',
        link: '/pages/gcqWarehouse/stockAlarmConfig',
      },
      {
        title: '库存告警信息管理',
        icon: 'bulb-outline',
        link: '/pages/gcqWarehouse/stockAlarmInfo',
      },
      {
        title: '库存盘点列表管理',
        icon: 'bulb-outline',
        link: '/pages/gcqWarehouse/stockCheck',
      },
      {
        title: '库存盘点详情管理',
        icon: 'bulb-outline',
        link: '/pages/gcqWarehouse/stockCheckDetail',
      },
      {
        title: '库存统计信息管理',
        icon: 'bulb-outline',
        link: '/pages/gcqWarehouse/stockStatistic',
      },
      // hhe 2023-3-30
      
    ]
  },
  // 管理内容库
  {
    title: '管理内容库',
    icon: 'layers-outline',
    children:[
      {
        title: '应急处置管理',
        icon: 'alert-triangle',
        children: [
          {
            title: '应急事件管理',
            link: '/pages/emergency/action',
          },
          {
            title: '应急事件处理管理',
            link: '/pages/emergency/mission',
          },
          {
            title: '应急短信记录管理',
            link: '/pages/contentManage/yjdxjlgl',
          },
        ]
      },
      {
        title: 'IRS同步批量数据',
        icon: 'cloud-upload',
        children:[
          {
            title: '主动服务提醒信息',
            link: '/pages/batchData/zdfwtxxx',
          },
          {
            title: '数字城管当日案卷信息',
            link: '/pages/batchData/szcgdrajxx',
          },
          {
            title: '水位监测当天记录信息',
            link: '/pages/batchData/swjcdtjlxx',
          },
          {
            title: '工单信息',
            link: '/pages/batchData/gdxx',
          },
          {
            title: '报警信息',
            link: '/pages/batchData/bjxx',
          },
          {
            title: '市长热线处理结果信息',
            link: '/pages/batchData/szrxcljgxx',
          },
          {
            title: '市长热线内容信息',
            link: '/pages/batchData/szrxnrxx',
          },
          {
            title: '民呼我为处理结果信息',
            link: '/pages/batchData/mhwwcljgxx',
          },
          {
            title: '民呼我为信息',
            link: '/pages/batchData/mhwwxx',
          },
          {
            title: '信访件信息',
            link: '/pages/batchData/xfjxx',
          },
          {
            title: '信访件其他信息',
            link: '/pages/batchData/xfjqtxx',
          },
          {
            title: '气象灾害预警信号信息',
            link: '/pages/batchData/qxzhyjxhxx',
          }
        ]
      },
      {
        title: '经济管理',
        icon: 'activity-outline',
        children: [
          {
            title: '主要经济数据管理1',
            link: '/pages/economy/majorEconomyData',
          },
          {
            title: '主要经济数据管理2',
            link: '/pages/economy/majorEconomy2Data',
          },
          {
            title: '企业税收数据管理1',
            link: '/pages/economy/corporateTaxData',
          },
          {
            title: '企业税收数据管理2',
            link: '/pages/economy/corporateTaxRegister',
          },
        ],
      },
      {
        title: '街道管理事项',
        icon: 'clipboard',
        link: '/pages/contentManage/jdglsx',
      },
    ]
  },
]

// export const MENU_ITEMS: NbMenuItem[] = [
//   {
//     title: '控制台',
//     icon: 'home-outline',
//     link: '/pages/dashboard',
//     home: true,
//   },
//   {
//     title: '功能模块',
//     group: true,
//   },
//   {
//     title: '大屏管理',
//     icon: 'monitor-outline',
//     children: [

//     ],
//   },


//   {
//     title: '大屏数据管理',
//     icon: 'monitor-outline',
//     children: [
//       {
//         title: '集团经营-各省销售额',
//         link: '/pages/desktopTemp/deskTempProvincialSales',
//       },
//       {
//         title: '集团经营-历年经营',
//         link: '/pages/desktopTemp/deskTempRevenueOverYears',
//       },
//       {
//         title: '集团经营-当年预算',
//         link: '/pages/desktopTemp/deskTempCurrentYearBudget',
//       },
//       {
//         title: '集团运营-当日任务',
//         link: '/pages/desktopTemp/deskTempCurrentDayTask',
//       },
//       {
//         title: '集团运营-当月任务',
//         link: '/pages/desktopTemp/deskTempCurrentMonthTask',
//       },
//       {
//         title: '集团运营-当年任务',
//         link: '/pages/desktopTemp/deskTempCurrentYearTask',
//       },
//       {
//         title: '集团运营-左半边',
//         link: '/pages/desktopTemp/deskTempOperationLeft',
//       },
//       {
//         title: '集团运营-地图',
//         link: '/pages/desktopTemp/deskTempOperationMap',
//       },
//       {
//         title: '集团运营-清分金额',
//         link: '/pages/desktopTemp/deskTempClearAmount',
//       },
//       {
//         title: '集团经营-其他',
//         link: '/pages/desktopTemp/deskTempManageOthers',
//       },
//       {
//         title: '嘉兴大屏-执行情况',
//         link: '/pages/desktopTemp/deskTempJiaxingTask',
//       },
//       {
//         title: '嘉兴大屏-其他',
//         link: '/pages/desktopTemp/deskTempJiaxing',
//       },
//       {
//         title: '集团安全-事故',
//         link: '/pages/desktopTemp/deskTempAccidentOverMonths'
//       },
//       {
//         title: '集团安全-运钞车',
//         link: '/pages/desktopTemp/deskTempCashTrunkOverMonths'
//       },
//       {
//         title: '集团安全-城市概况',
//         link: '/pages/desktopTemp/deskTempSecInfoOverCities'
//       },
//       {
//         title: '集团安全-其他',
//         link: '/pages/desktopTemp/deskTempSecInfoOthers'
//       }

//     ],
//   },


//   {
//     title: '系统管理',
//     icon: 'settings-outline',
//     children: [
//       {
//         title: '字典管理',
//         link: '/pages/system/dictionary',
//       },

//       {
//         title: '模块管理',
//         link: '/pages/system/module',
//       },

//       {
//         title: '模块功能管理',
//         link: '/pages/system/moduleFunc',
//       },

//       {
//         title: '角色管理',
//         link: '/pages/system/role',
//       },

//       {
//         title: '用户管理',
//         link: '/pages/system/user',
//       }
//     ],
//   },
// // 日志管理
//   {
//     title: '日志管理',
//     icon: 'clipboard-outline',
//     children: [
//       {
//         title: '登录日志',
//         link: '/pages/logs/loginLog',
//       },
//       {
//         title: '操作日志',
//         link: '/pages/logs/operateLog',
//       },
//     ],
//   },
//   // 人事管理
//   {
//     title: '人事管理',
//     icon: 'people-outline',
//     children: [
//       {
//         title: '员工信息',
//         link: '/pages/oa/oaUser',
//       },
//       {
//         title: '转正申请',
//         link: '/pages/oa/oaApplyBeFull',
//       },
//       {
//         title: '学历技能备案',
//         link: '/pages/oa/oaApplyLearning',
//       },
//       {
//         title: '学历奖励申请',
//         link: '/pages/oa/oaApplyLearningReward',
//       },
//       {
//         title: '技能职称津贴申请',
//         link: '/pages/oa/oaApplySubsidy',
//       },
//       {
//         title: '加班申请',
//         link: '/pages/oa/oaApplyOvertime',
//       },
//       {
//         title: '请假申请',
//         link: '/pages/oa/oaApplyVacation',
//       },
//       {
//         title: '调动申请',
//         link: '/pages/oa/oaApplyRelieving',
//       },
//       {
//         title: '培训申请',
//         link: '/pages/oa/oaApplyTraining',
//       },
//       {
//         title: '销假申请',
//         link: '/pages/oa/oaApplyCancelVacation',
//       },
//       {
//         title: '离职申请',
//         link: '/pages/oa/oaApplyLeave',
//       },
//       {
//         title: '非正式员工',
//         link: '/pages/oa/oaUserInformal',
//       },
//     ],
//   },
 
//  // 绩效管理
//   {
//     title: '绩效管理',
//     icon: 'layers-outline',
//     children: [
//       {
//         title: '业务-区域管理',
//         link: '/pages/kpi/kpi-region-manage',
//       }, {
//         title: '业务-网点管理',
//         link: '/pages/kpi/kpi-network-manage',
//       }, {
//         title: '业务-级别管理',
//         link: '/pages/kpi/kpi-level-manage',
//       }, {
//         title: '业务-线路管理',
//         link: '/pages/kpi/kpi-line-manage',
//       }, {
//         title: '业务-中队管理',
//         link: '/pages/kpi/kpi-detach-manage',
//       }, {
//         title: '业务-员工管理',
//         link: '/pages/kpi/kpi-personnel-manage',
//       }, {
//         title: '绩效-员工',
//         link: '/pages/kpi/employee',
//       }, {
//         title: '绩效-员工行政',
//         link: '/pages/kpi/employee-admin',
//       }, {
//         title: '绩效-员工固定任务',
//         link: '/pages/kpi/employee-stable',
//       }, {
//         title: '绩效-员工临时任务',
//         link: '/pages/kpi/employee-temporary',
//       }, {
//         title: '绩效-员工ATM任务',
//         link: '/pages/kpi/employee-atm',
//       }, {
//         title: '绩效-员工其他任务',
//         link: '/pages/kpi/employee-others',
//       }, {
//         title: '绩效-员工特殊任务',
//         link: '/pages/kpi/employee-special',
//       },
//     ],
//   },
//   // 车辆管理
//   {
//     title: '车辆管理',
//     icon: 'car-outline',
//     children: [
//       {
//         title: '车辆信息管理',
//         link: '/pages/vehicle/vehicleCarInfo',
//       },
//       {
//         title: '车载设备管理',
//         link: '/pages/hik7200/carDevice',
//       },
//       {
//         title: '车载告警管理',
//         link: '/pages/hik7200/carAlarm',
//       },
//       {
//         title: 'GPS管理',
//         link: '/pages/hik7200/gps',
//       },
//       {
//         title: '车载通知管理',
//         link: '/pages/hik7200/carState',
//       }
//     ]
//   },
//   // 枪支管理
//   {
//     title: '枪支管理',
//     icon: 'scissors-outline',
//     children: [
//       {
//         title: '枪支信息管理',
//         link: '/pages/device/gunGunInfo',
//       },
//       {
//         title: '枪弹库管理',
//         link: '/pages/device/gunWarehouse',
//       },
//       {
//         title: '弹药管理',
//         link: '/pages/device/gunBulletInfo',
//       },
//       {
//         title: '弹药出入库管理',
//         link: '/pages/device/gunBulletLog',
//       },
//       {
//         title: '枪支运营管理',
//         // icon: 'brush-outline',
//         link: '/pages/device/gun',

//       },
//       {
//         title: '枪支领用管理',
//         // icon: 'brush-outline',
//         link: '/pages/device/gunUseInfo',

//       },
//       {
//         title: '枪支告警管理',
//         link: '/pages/device/gunAlarm',
//       },
//       {
//         title: '枪支GPS管理',
//         link: '/pages/device/gunGps',
//       },
//     ],
//   },
//   // 库房管理
//   {
//     title: '库房管理',
//     icon: 'calendar-outline',
//     children: [
//       {
//         title: '箱子信息管理',
//         link: '/pages/warehouse/kf-boxinfo',
//       },
//       {
//         title: '库存管理',
//         link: '/pages/warehouse/kf-boxstock',
//       },
//       {
//         title: '入库交接管理',
//         link: '/pages/warehouse/kf-instore',
//       },
//       {
//         title: '内部交接管理',
//         link: '/pages/warehouse/kf-intertask',
//       },
//       {
//         title: '请求库房信息管理',
//         link: '/pages/warehouse/kf-taskrequest',
//       },
//       {
//         title: '下发数据管理',
//         link: '/pages/warehouse/kf-boxsenddata',
//       },
//       {
//         title: '任务箱子信息监控管理',
//         link: '/pages/warehouse/kf-taskboxmonitored',
//       },
//     ],
//   },
//   // 交接管理
//   {
//     title: '交接管理',
//     icon: 'calendar-outline',
//     children: [
//       {
//         title: '网点管理',
//         link: '/pages/handover/jjBankOutLetInfo',
//       },
//       {
//         title: '线路管理',
//         link: '/pages/handover/jjRouteinfo',
//       },
//       {
//         title: '线路站点管理',
//         link: '/pages/handover/jjRouteoutletinfo',
//       },
//       {
//         title: '车队管理',
//         link: '/pages/handover/jjTeaminfo',
//       },
//       {
//         title: '押运车管理',
//         link: '/pages/handover/jjVehicleinfo',
//       },
//       {
//         title: '工作组管理',
//         link: '/pages/handover/jjWorkgroupinfo',
//       },
//       {
//         title: '用户信息管理',
//         link: '/pages/handover/jjUserinfo',
//       },
//       {
//         title: '角色信息管理',
//         link: '/pages/handover/jjRoleinfo',
//       },
//       {
//         title: '任务人员管理',
//         link: '/pages/handover/jjTaskUser',
//       },
//       {
//         title: '请求交接信息管理',
//         link: '/pages/handover/jjTaskrequest',
//       },
//       {
//         title: '任务管理',
//         link: '/pages/handover/jjTask',
//       },
//       {
//         title: '子任务管理',
//         link: '/pages/handover/jjTaskchildren',
//       },
//     ],
//   },
//  // 业务管理
//   {
//     title: '业务管理',
//     icon: 'briefcase-outline',
//     children: [
//       {
//         title: '合同信息管理',
//         link: '/pages/contract/conContract',
//       },
//       {
//         title: '结算单管理',
//         link: '/pages/contract/conBill',
//       },
//       {
//         title: '银行网点管理',
//         link: '/pages/contract/conBranchBank',
//       },
//       {
//         title: '上门服务点管理',
//         link: '/pages/contract/conBranchServer',
//       },
//       {
//         title: '自助设备管理',
//         link: '/pages/contract/conBranchAtm',
//       }
//     ]
//   },
//   // IPO管理
//   {
//     title: 'IPO管理',
//     icon: 'folder-outline',
//     children: [
//       {
//         title: '房屋租赁',
//         link: '/pages/ipo/ipoHouseLease',
//       },
//       {
//         title: '房产转租',
//         link: '/pages/ipo/ipoHouseSublease',
//       },
//       {
//         title: '承租经营性资产',
//         link: '/pages/ipo/ipoLeaseBusAssets',
//       },
//       {
//         title: '承租非经营性资产',
//         link: '/pages/ipo/ipoLeaseNonBusAssets',
//       },
//       {
//         title: '固定资产',
//         link: '/pages/ipo/ipoFixedAssets',
//       },
//       {
//         title: '行政处罚',
//         link: '/pages/ipo/ipo-punish',
//       },
//       {
//         title: '销售与服务合同',
//         link: '/pages/ipo/ipoXsFwContract',
//       },
//       {
//         title: '资质',
//         link: '/pages/ipo/ipoQualification',
//       },
//       {
//         title: '商标',
//         link: '/pages/ipo/ipoBrand',
//       },
//       {
//         title: '域名',
//         link: '/pages/ipo/ipoDomain',
//       },
//       {
//         title: '专利',
//         link: '/pages/ipo/ipoPatent',
//       },
//       {
//         title: '软著',
//         link: '/pages/ipo/ipoSoftCopyright',
//       },
//       {
//         title: '借款合同',
//         link: '/pages/ipo/ipoLoanContract',
//       },
//       {
//         title: '资产财务',
//         link: '/pages/ipo/ipoAssetFinance',
//       },
//       {
//         title: '组织人事',
//         link: '/pages/ipo/ipoOrganizationPersonnel',
//       },
//     ]
//   },

//   //安邦专属功能模块-end

// // 拱宸桥专属模块---start cjj 2022 11/11

//   //园区专属功能模块-start

//   {
//     title: '门禁管理',
//     icon: 'bell-outline',
//     children: [
//       {
//         title: '门禁列表',
//         link: '/pages/door-manage/door',
//       },
//     ],
//   },

//   //管理人员库
//   {
//     title: '管理人员库',
//     icon: 'people-outline',
//     children:[
//       {
//         title: '街道基础信息',
//         children: [
//           {
//             title: '机构管理',
//             link: '/pages/empl/department',
//           },
//           {
//             title: '机构人员管理',
//             link: '/pages/empl/employee',
//           },
//         ]
//       },
//       {
//         title: '老人基本信息',
//         link: '/pages/batchData/lrjbxx',
//       },
//       {
//         title: '人员户籍信息',
//         link: '/pages/batchData/ryhjxx',
//       },
//       {
//         title: '居民基本信息',
//         link: '/pages/batchData/jmjbxx',
//       },
//       {
//         title: '残疾人员基本信息',
//         link: '/pages/batchData/cjryjbxx',
//       },
//       {
//         title: '微消人员动态信息',
//         link: '/pages/batchData/wxrydtxx',
//       },
//       {
//         title: '街道党建管理',
//         children: [
//           {
//             title: '党员管理',
//             link: '/pages/party/party-member',
//           },
//           {
//             title: '党组织管理',
//             link: '/pages/party/party-org',
//           },
//           {
//             title: '党建管理',
//             link: '/pages/party/party-building',
//           },
//           {
//             title: '党员发展管理',
//             link: '/pages/party/party-develop',
//           },
//         ],
//       },
//       {
//         title: '残疾人员信息',
//         link: '/pages/interfaceData/cjryxx',
//       },
//       {
//         title: '人员基础信息',
//         link: '/pages/interfaceData/ryjcxx',
//       },
//       {
//         title: '物业人员信息',
//         link: '/pages/peopleManage/wyryxx',
//       },
//       {
//         title: '志愿者信息',
//         link: '/pages/peopleManage/zyzxx',
//       },
//       {
//         title: '微消站人员信息',
//         link: '/pages/peopleManage/wxzryxx',
//       },
//       {
//         title: '网格员信息',
//         link: '/pages/peopleManage/wgyxx',
//       }
//     ]
//   },
//   //管理对象库
//   {
//     title: '管理对象库',
//     icon: 'inbox-outline',
//     children:[
//       {
//         title: '商家信息',
//         link: '/pages/batchData/sjxx',
//       },
//       {
//         title: '楼栋基本信息',
//         link: '/pages/batchData/ldjbxx',
//       },
//       {
//         title: '智治系统_企业基本信息',
//         link: '/pages/batchData/zzxtqyjbxx',
//       },
//       {
//         title: '加油站信息',
//         link: '/pages/batchData/jyzxx',
//       },
//       {
//         title: '安全生产企业信息',
//         link: '/pages/batchData/aqsccxqyxx',
//       },
//       {
//         title: '网格基本信息',
//         link: '/pages/batchData/wgjbxx',
//       },
//       {
//         title: '社区基本信息',
//         link: '/pages/batchData/sqjbxx',
//       },
//       {
//         title: '微消站_人员信息',
//         link: '/pages/batchData/wxzryxx',
//       },
//       {
//         title: '微消站_设施信息',
//         link: '/pages/batchData/wxzssxx',
//       },
//       {
//         title: '微消站信息',
//         link: '/pages/batchData/wxzxx',
//       },
//       {
//         title: '杭州市统一地址库',
//         link: '/pages/batchData/hzstydzkxx',
//       },
//       {
//         title: '安全生产不良记录黑名单',
//         link: '/pages/batchData/aqscbljlhmdxx',
//       },
//       {
//         title: '视频管理',
//         children: [
//           {
//             title: '摄像头管理',
//             link: '/pages/video/camera',
//           },
//           {
//             title: '视频实时播放',
//             link: '/pages/video/real-time-playing',
//           },
//           {
//             title: '视频历史回放',
//             link: '/pages/video/playback',
//           },
    
//         ],
//       },
//       {
//         title: '基础信息管理',
//         children: [
//           {
//             title: '海豚平台管理',
//             link: '/pages/base-info/dolphin',
//           },
//         ],
//       },
//       {
//         title: '店铺信息',
//         link: '/pages/object-manage/dpxx',
//       },
//     ]
//   },
//   // 物资库
//   {
//     title: '物资库',
//     icon: 'car-outline',
//     children:[
//       {
//         title: '应急（避灾）管理',
//         children: [
//           {
//             title: '避灾点管理',
//             link: '/pages/emergencydisaster/disaster',
//           }, 
//           {
//             title: '避灾点物资管理',
//             link: '/pages/emergencydisaster/disasterMaterials',
//           }, 
//         ],
//       },
//       {
//         title: '应急物资仓库信息',
//         link: '/pages/batchData/yjwzckxx',
//       },
//       {
//         title: '应急（消防）仓库管理',
//         children: [
//           {
//             title: '消防仓库管理',
//             link: '/pages/emergencyfire/stockFire',
//           }, 
//           {
//             title: '消防库存管理',
//             link: '/pages/emergencyfire/warehouseFire',
//           }, 
//         ],
//       },
//       {
//         title: '应急（防汛防台）仓库管理',
//         children: [
//           {
//             title: '防汛仓库管理',
//             link: '/pages/emergencyflood/stockFlood',
//           }, 
//           {
//             title: '防汛库存管理',
//             link: '/pages/emergencyflood/warehouseFlood',
//           }, 
//         ],
//       },
      
//     ]
//   },
//   // 管理内容库
//   {
//     title: '管理内容库',
//     icon: 'layers-outline',
//     children:[
//       {
//         title: '应急处置管理',
//         children: [
//           {
//             title: '应急事件管理',
//             link: '/pages/emergency/action',
//           },
//           {
//             title: '应急事件处理管理',
//             link: '/pages/emergency/mission',
//           },
//         ]
//       },
//       {
//         title: 'IRS同步批量数据',
//         children:[
//           {
//             title: '主动服务提醒信息',
//             link: '/pages/batchData/zdfwtxxx',
//           },
//           {
//             title: '数字城管当日案卷信息',
//             link: '/pages/batchData/szcgdrajxx',
//           },
//           {
//             title: '水位监测当天记录信息',
//             link: '/pages/batchData/swjcdtjlxx',
//           },
//           {
//             title: '工单信息',
//             link: '/pages/batchData/gdxx',
//           },
//           {
//             title: '报警信息',
//             link: '/pages/batchData/bjxx',
//           },
//           {
//             title: '市长热线处理结果信息',
//             link: '/pages/batchData/szrxcljgxx',
//           },
//           {
//             title: '市长热线内容信息',
//             link: '/pages/batchData/szrxnrxx',
//           },
//           {
//             title: '民呼我为处理结果信息',
//             link: '/pages/batchData/mhwwcljgxx',
//           },
//           {
//             title: '民呼我为信息',
//             link: '/pages/batchData/mhwwxx',
//           },
//           {
//             title: '信访件信息',
//             link: '/pages/batchData/xfjxx',
//           },
//           {
//             title: '信访件其他信息',
//             link: '/pages/batchData/xfjqtxx',
//           },
//           {
//             title: '气象灾害预警信号信息',
//             link: '/pages/batchData/qxzhyjxhxx',
//           }
//         ]
//       },
//       {
//         title: '经济管理',
//         children: [
//           {
//             title: '主要经济数据管理1',
//             link: '/pages/economy/majorEconomyData',
//           },
//           {
//             title: '主要经济数据管理2',
//             link: '/pages/economy/majorEconomy2Data',
//           },
//           {
//             title: '企业税收数据管理1',
//             link: '/pages/economy/corporateTaxData',
//           },
//           {
//             title: '企业税收数据管理2',
//             link: '/pages/economy/corporateTaxRegister',
//           },
//         ],
//       }
//     ]
//   }

// ];

