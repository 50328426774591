import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NB_WINDOW, NbSearchService, NbWindowService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ShowToastService } from '../../../services/show-toast.service';
import { CommunicationService } from '../../../services/communication.service';
import { ChkPowerService } from '../../../services/chk-power.service';
import { Router, NavigationExtras } from '@angular/router';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {


  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  user: any;

  public readonly getWarningManageList$: Observable<boolean>;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'material-light',
      name: 'Material Light',
    },
    {
      value: 'material-dark',
      name: 'Material Dark',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: '退出' }];

  searchText = '全系统搜索';

  company = 'CETC';

  title = '智慧园区平台';

  badgeText = '';
  alarmNum = 0;//警告数量 
  oldshow = true;

  socket;  //WebSocket 对象
  stompClient;

  windowView;

  mp3 = new Audio("/assets/music/99.mp3");
  mp3Playing = false;
          
  public constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private ChkPowerService: ChkPowerService,
    private searchService: NbSearchService,
    private showToastService: ShowToastService,
    private communicationService:CommunicationService,
    protected router: Router,
    public windowService: NbWindowService,
    @Inject(NB_WINDOW) private window,
    public http: HttpClient,

  ) {
    this.materialTheme$ = this.themeService.onThemeChange()
      .pipe(map(theme => {
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      }));

    this.searchService.onSearchSubmit()
      .subscribe((data: any) => {
        this.searchText = data.term;
        //this.window.alert(`${value} was searching!`)
        let navigateExtras: NavigationExtras = {
          queryParams: { "search": this.searchText, "timeid": Math.random() }
        }
        this.router.navigate(["/pages/miscellaneous/globalsearch"], navigateExtras);

      });

      var self = this;
      this.mp3.addEventListener("ended", function (){
        self.mp3Playing = false; //播放完了，设置为未播放状态
      });
  }

  ngOnInit() {
    this.company = localStorage.getItem('platformBaseInfo-company');
    this.title = localStorage.getItem('platformBaseInfo-title');

    this.currentTheme = this.themeService.currentTheme;

    var sex = localStorage.getItem('manage-CurrentUserSex');
    var picture;
    if (sex == "男")
      picture = "assets/images/employee/jack.png";
    else
      picture = 'assets/images/employee/kate.png';


    this.user = { name: localStorage.getItem('manage-CurrentUserName'), picture: picture };


    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
      });

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        //this.window.alert(`${title} was clicked!`)
        this.ChkPowerService.loginOut();
      }
      );
    //this.updateAlarmNumber(this);
    //setInterval(this.updateAlarmNumber, 60000, this);
    //this.connectWebSocket(true,this);

  }

  connectWebSocket(isAlert,self) {
    //debugger;
    self.socket = new SockJS(localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ':' + localStorage.getItem('gatewayServerPort') + '/iot4-platform2-system-server/endpointWs?random=' + Math.random());
    self.stompClient = Stomp.over(self.socket);
    self.stompClient.connect({}, (frame) => {
      //console.log('Connected: ' + frame);
      self.showToastService.showToast('success', "实时告警总数推送已连接", "告警总数推送已连接");
      self.stompClient.subscribe('/topic/callback', function (r) {
        console.log(r.body);
        var obj = JSON.parse(r.body);
        
        if (obj.returnString !== "success") {
          self.showToastService.showToast('danger', '发生错误', obj.msg);
          return;
        }
        var number = obj.data;
        localStorage.setItem('warningNum', number);

        if (number == "0") {
          self.badgeText = '';
          self.alarmNum = '0';
        }
        else {
          
          self.badgeText = number;
          self.alarmNum = number;

          if(self.mp3Playing == false)//不在播放状态才能播放，避免多个声音一起播放
          {
            self.mp3Playing = true; //开始播放
            self.mp3.play();            
          }  
        }
      });
      self.stompClient.subscribe('/topic/dealWarning', function (r) {
        console.log(r.body);
        var obj = JSON.parse(r.body);
        if (obj.returnString !== "success") {
          self.showToastService.showToast('danger', '发生错误', obj.msg);
          return;
        }
        var number = obj.data;
        localStorage.setItem('warningNum', number);

        if (number == "0") {
          self.badgeText = '';
          self.alarmNum = '0';
        }
        else {
          self.badgeText = number;
          self.alarmNum = number;
        }
      });
      
    }, function (frame) {
      //self.connectedWebSocket = '已断开';
      if (isAlert == true)
        self.showToastService.showToast('danger', "实时告警总数推送已断开", "告警总数推送已断开");

      //重新连接，不需要推送告警        
      setTimeout(() => {
        self.connectWebSocket(false,self);
      }, 15000);

    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    //debugger
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  goAlarm() {
    let queryParams: NavigationExtras = {
      queryParams: { 'type': '1', 'isNew': '1' }
    }
    this.router.navigate(['/pages/event', 'warning-manage'], queryParams);
    this.communicationService.reflashContent("");
    this.updateAlarmNumber(this);
    //this.windowView.close();
  }

  updateAlarmNumber(self) {
    self.http.get(localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot4-platform2-system-server/warningfindWarningNodealNum",
      {
        withCredentials: true,
        responseType: 'text',
      }).subscribe(Response => {
        var obj = JSON.parse(Response)
        if (obj.returnString !== "success") {
          this.showToastService.showToast('danger', '发生错误', obj.msg);
          return;
        }
        //判断是否需要播放音乐
        //var olddata = localStorage.getItem('warningNum');
        var number = obj.data;

        localStorage.setItem('warningNum', number);

        if (number == "0") {
          self.badgeText = '';
          self.alarmNum = '0';
        }
        else {
          self.badgeText = number;
          self.alarmNum = number;
        }
      }, error => {
        self.badgeText = '';
      });
  }


}
