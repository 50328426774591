import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbWindowRef } from '@nebular/theme';
import axios from 'axios';
import { MENU_ITEMS } from '../app.component';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
//模块功能通用服务 added by 葛明 2022-8-19
//集成了大部分模块里的函数方法，精简了调用方式
export class GeneralModuleFuncService {
  //menu = MENU_ITEMS;  

  constructor(private router: Router) { }

  //通过菜单数组对象取完整模块名称
  getTotalModuleNameByMenu(menu,moduleName,moduleLink)
  {
    //debugger;   
    if(!!menu == false)
    {
      menu = MENU_ITEMS;
    }

    for(var j = 0 ; j < menu.length ; j++)
    {
      var m = menu[j];
      //开始比对
      if( !!moduleLink && m.link === moduleLink) //有路由，比较路由
      {
        
        return m.title;  //找到
      }
      else if(m.title === moduleName )  //没有路由，比较名称
      {
        
        return m.title;  //找到
      }
      else //没找到,去子级找找
      {
        var r = this.recurveFindMenu(m,moduleName,moduleLink);
        if(!!r)
        {          
          return m.title + "***" + r;                
        } 
      }            
    }
    return "";
  }

  //递归寻找菜单项
  recurveFindMenu(menu,moduleName,moduleLink)
  {
    if(menu.children) //有子菜单，则循环递归查找子菜单数组
    {
      for(var k = 0 ; k < menu.children.length ; k++)
      {
        var m = menu.children[k];
        //没有子菜单或者子菜单里找不到，则与自身比较 
        if( !!moduleLink && m.link === moduleLink) //有路由，比较路由
        {
          
          return m.title;  //找到
        }
        else if(m.title === moduleName )  //没有路由，比较名称
        {
          
          return m.title;  //找到
        }
        else
        {
          var r = this.recurveFindMenu(m,moduleName,moduleLink);
          if(!!r)
          {
            return m.title + "***" + r;          
          }
        } 
      }      
    }
      
  }

  //初始化功能模块中的字段对象
  initModuleColumns(self,dateSearchColumn = "") 
  {
    //debugger
    if(!!MENU_ITEMS == false || MENU_ITEMS.length < 2) //如果没有菜单内容，则重新登录
    {
      this.router.navigateByUrl("auth/login",{ replaceUrl: true });
    }
    var totalModuleName = this.getTotalModuleNameByMenu(MENU_ITEMS, self.moduleName, this.router.routerState.snapshot.url);
    

    var str = sessionStorage.getItem('platformModules-' + totalModuleName);
    if(!!str)
    {
      var obj = JSON.parse(str);
      self.columns = obj.columns;    
      self.moduleName = obj.name;
      self.columnsMap = self.handleColumnsService.transformToMap(self.columns);  
      //初始化导出时需要传递的字段信息数组
      self.columns.forEach(item => {
        self.cols.push({explain:item.explain,name:item.name,title:item.title,source:item.source,showInInfo:item.showInInfo});
        if(!!item.searchType && item.searchType != "非查询条件")
        {
          self.searchConditions[item.name] = '';
        }
        if(!!item.editType && item.editType != "非编辑字段" && !!self.editObj)
        {
          self.editObj[item.name] = customJsFunc.getNewObjectDefaultValue(item.name);//得到初始化新增对象的默认值
        }
      })
      //初始化查看tabs
      //debugger;
      self.columns.forEach(item => {
        if(!!item.tab)
        {
          var tabStrs = item.tab.split(',');
          for(var tabStr of tabStrs)
          {
            var parts = tabStr.split('-');
            var id = parts[0];
            var name = parts[1];     
            if(!!self.tabs[id])
            {
              self.tabs[id].columns.push(item);
            }
            else
            {
              self.tabs[id] = {title:name,columns:[item]};
            }
          }
          
        }
        
      })
    }    

    if(!!dateSearchColumn)
    {
      var start:Date = new Date();
      start.setDate(1);
      start.setHours(0,0,0);
      self.searchConditions[dateSearchColumn + 'Start'] = start;
      var end:Date = new Date();    
      end.setHours(23,59,59);
      self.searchConditions[dateSearchColumn + 'End'] = end;
    }    
  }

  //全局搜索触发搜索方法
  wholeSearch(conditionName,self,dateSearchColumn = "")
  {
    self.activatedRoute.queryParams.subscribe(data =>  {
        var search = data.search;
        if(search){
          self.searchConditions[conditionName] = search.trim();
          if(!!dateSearchColumn)
          {
            self.searchConditions[dateSearchColumn + 'Start'] = "";
          }
        } 
        if(!dateSearchColumn) //如果没有时间查询条件，则开始查询，如果有，则不查询。因为时间查询条件在设置初始值的时候会自动触发一次查询
        {
          self.getList();//查询结果
        }
        
      }      
    );
  }

  
  //得到api查询参数-主内容是树的情况
  getQueryParamsForTree(type,obj,self)
  {
    var _params = customJsFunc.deepClone(self.searchConditions);  
    
    if(type == "导出")
    {      
      _params['columns'] = JSON.stringify(self.cols);
      _params['exportType'] = !!obj.exportType ? obj.exportType : "";  //导出类型
      if(obj.pageIndex)
      {
          _params['pageIndex'] = obj.pageIndex;
      }
    }      
    return _params;
  }

  //得到api查询参数-主内容是列表的情况
  getQueryParamsForList(type,obj,self)
  {
    var _params = customJsFunc.deepClone(self.searchConditions);
        
    if(type == "导出")
    {      
        _params['columns'] = JSON.stringify(self.cols);
        _params['exportType'] = !!obj.exportType ? obj.exportType : "";  //导出类型
        if(obj.pageIndex)
        {
            _params['pageIndex'] = obj.pageIndex;
        }
    }
    else  //查询
    {
        _params['pageSize'] = self.current_option.toString();
        _params['pageIndex'] = (self.pageIndex - 1).toString();
    }        
       
    return _params;
  }

  //查询组件回调接口，验证查询条件并进行查询
  searchWithConds(obj,self,mainInfoType = 'list')
  {
    //设置查询条件值
    customJsFunc.setSearchConditions(self.searchConditions,obj);
    if(!!mainInfoType && mainInfoType == "tree") //主内容是树的情况
    {
      self.getList();//获得查询结果
    }
    else //主内容是列表的情况,默认值
    {
      //获取第一页的查询结果
      self.pageIndex = 1;
      self.getList();
    }     
  }

  //去后台发起查询请求，得到查询结果
  getList(self,url,dateSearchColumn = "",mainInfoType = 'list',nodeType = 'Dictionary')
  {   
    //debugger 
    //得到api查询参数
    var _params = self.getQueryParams('查询',null);

    if(!!mainInfoType && mainInfoType == "tree") //主内容是树的情况
    {
       //this.getQueryParamsForTree('查询',null,self);

      var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/" + self.server + url;
      self.http.get(api, {withCredentials: true, params:_params}).subscribe(Response =>{
        var pageData;
        if(Response && Response["code"]!=undefined)
        {
          var code = Response["code"];
          if(code == 0) //成功
          {
            pageData = Response["data"];
          }
          else  //失败
          {
            var msg = Response["msg"];
            self.showToastService.showToast('warning', '发生异常', msg);
            return;
          }
        }
        else
        {
          pageData = Response;
        }
        //debugger
        var items = pageData['rows'];      
        var nodes = self.parseToTreeDataService.parseToDictionaryEntry(items,nodeType);
        self.dataSource = self.dataSourceBuilder.create(nodes, self.getters);     
        if (items.length == 0)
        {        
          self.showToastService.showToast('warning', '没有数据', "没有搜索到任何记录信息!");
        }   
      },error => {        
        console.log(error);
        self.showToastService.showToast('danger', '发生错误', !!error.error && !!error.error.body ? error.error.body : error.message);
      })
    }
    else //主内容是列表的情况,默认值
    {    
      //删除时间条件为空的查询条件
      if(!!dateSearchColumn) //有时间查询条件，需要检查是否清空时间框
      {
        if(_params[dateSearchColumn + 'Start'] == "")
        {  
          delete  _params[dateSearchColumn + 'Start'];
        }
        if(_params[dateSearchColumn + 'End'] == "")
        {      
          delete  _params[dateSearchColumn + 'End'];
        }
      }
      

      var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/" + self.server + url;
      self.http.get(api, {withCredentials: true, params:_params}).subscribe(Response => {
        //debugger;
        if(!!dateSearchColumn) //有时间查询条件，需要检查是否清空时间框
        {
          if(self.searchConditions[dateSearchColumn + 'Start'] == "")
          {  
            //self.searchConditions[dateSearchColumn + 'Start'] = "" ;
            $('#' + dateSearchColumn + 'StartInput').val('');
          }
          if(self.searchConditions[dateSearchColumn + 'End'] == "")
          {      
            //self.searchConditions[dateSearchColumn + 'End'] = "";
            $('#' + dateSearchColumn + 'EndInput').val('');
          }
        }       

        var pageData;
        if(Response && Response["code"]!=undefined)
        {
          var code = Response["code"];
          if(code == 0) //成功
          {
            pageData = Response["data"];
          }
          else  //失败
          {
            var msg = Response["msg"];
            self.showToastService.showToast('warning', '发生异常', msg);
            return;
          }
        }
        else
        {
          pageData = Response;
        }
        //debugger
        self.totalPages = pageData["totalPages"]; //总页数
        self.totalElements = pageData["totalElements"]; //总行数 
        self.items = pageData["content"];
        self.pages = [];
        self.currentPages = []; //当前页所在的10页范围，以每10页为一个范围
        self.pageChangeService.createPages(self.pageIndex, self.totalPages, self.pages, self.currentPages);
        if (self.items.length == 0) {          
          if (self.pageIndex > 1) {
            self.pageIndex = self.pageIndex - 1;
            self.getList();
          }
          else{
            self.showToastService.showToast('warning', '没有数据', "没有搜索到任何记录信息!");
          }            
        }
      }, error => {
        console.log(error);
        self.showToastService.showToast('danger', '发生错误', !!error.error && !!error.error.body ? error.error.body : error.message);
      })
    }      
  }  


  //查看记录详情
  view(item,self)
  {    
    self.windowService.open(
      self.viewTemplate,
      {
        title: '查看' + self.infoName,
        context: item,
      },
    );        
  }
  //新增记录
  add(self)
  {
    var editObjCopy = customJsFunc.deepClone(self.editObj);
    
    var win:NbWindowRef = self.windowService.open(
      self.editTemplate,
      {
        title: '新增' + self.infoName,
        hasBackdrop: false,
        context: editObjCopy,        
      },
    );
    win.config.context['window'] = win;    
  }
  //编辑记录
  edit(item,self)
  { 
    //debugger
    var win:NbWindowRef = self.windowService.open(
      self.editTemplate,
      {
        title: '编辑' + self.infoName,
        hasBackdrop: false,
        context: item,        
      },
    );
    win.config.context['window'] = win;    
    item.editing = true;
    win.onClose.subscribe(() =>{
      item.editing = false;
    });  
  }

  //删除记录
  delete(item,self,url,titleColumn = 'name')
  {
    var title = "";
    if(item[titleColumn])
    {
      title = item[titleColumn];
    }
    else
    {
      title = item['id'];
    }
    self.dialogService.open(self.deleteTemplate, { context: title })
    .onClose.subscribe(confirmDel => {
      //console.log(confirmDel);
      if(confirmDel){
        var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/" + self.server + url;
        self.http.get(api,{withCredentials: true, params:
          {
            userName:localStorage.getItem('manage-CurrentUserName'),
            title: self.infoName,
            id:item.id
          }}).subscribe(Response =>{ 

            if(Response && Response["code"]!=undefined)
            {
              var data = Response;
              if (data &&  data["code"] == 0) {
              
                self.showToastService.showToast('success', '删除成功', "信息删除成功!");     
                self.getList();//数据发生改变，需重新加载      
              }
              else {
                self.showToastService.showToast('danger', '删除失败', data["msg"]);
              }
            }
            else
            {
              if (Response && Response.data == 'success'){              
                self.showToastService.showToast('success', '删除成功', "信息删除成功!");                  
                self.getList(); //数据发生改变，需重新加载            
              }
              else {
                self.showToastService.showToast('danger', '删除失败', Response);
              }
            }                       
          },error => {
            self.showToastService.showToast('danger', '发生错误', !!error.error && !!error.error.body ? error.error.body : error.message);
          })
      }
    });
  }
  //保存
  save(win:NbWindowRef,editObjCopy,self,url)
  {    
    //debugger
    win.close();
    var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/" + self.server + url;   
    api = api + "?userName=" + localStorage.getItem('manage-CurrentUserName') + "&title=" + self.infoName;

    var _params = {
      // userName:localStorage.getItem('manage-CurrentUserName'),
      // title: this.infoName
    };
    for(var key in editObjCopy)
    {
        var temp = editObjCopy[key];
        _params[key] = !!temp ? temp : "" ;
    }
    //var dataFormat = qs.stringify(_params);
    //debugger;
    axios.post(api,_params, {
      //headers:{'X-Token':localStorage.getItem('manage-CurrentToken'), 'userName':encodeURIComponent(localStorage.getItem('manage-CurrentUserName')), 'title':encodeURIComponent(this.infoName)},
    } ).then(Response =>{  
      //debugger

        if(Response && Response.data && Response.data["code"]!=undefined)
        {
          var data = Response.data;
          if (data &&  data["code"] == 0) {
            var tip = '';
            if (editObjCopy.id) {
              tip = '编辑保存成功!';
            }
            else {
              tip = '添加保存成功!';
            }
            self.showToastService.showToast('success', '保存成功', tip);       
            self.getList();
          }
          else {
            self.showToastService.showToast('danger', '发生错误', data["msg"]);
          }
        }
        else
        {
          if (Response && Response.data == 'success')
          {
            var tip = '';
            if(editObjCopy.id)
            {
                tip = '编辑保存成功!';
            }
            else
            {
                tip = '添加保存成功!';
            }
            self.showToastService.showToast('success', '保存成功', tip);
            //字典数据发生改变，需重新加载
            self.getList();          
          }
          else
          {
            self.showToastService.showToast('danger', '发生错误', Response.data);
          }
        }        
      }).catch(error => {     
        console.log(error);
        self.showToastService.showToast('danger', '发生错误', !!error.error && !!error.error.body ? error.error.body : error.message);
      })
  }  

  //拉取数据
  pullDown(url,self)
  {
    debugger
    var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort')  + url;
    api = api + "?userName=" + localStorage.getItem('manage-CurrentUserName') + "&title=" + self.infoName;

    var _params = {
      // userName:localStorage.getItem('manage-CurrentUserName'),
      // title: this.infoName
    };
    axios.post(api, _params).then(Response => {
      var tip = Response.data.result;

      if (Response && Response.data.code == 200) {
        self.showToastService.showToast('success', '拉取成功', tip);       
        self.getList();
      }
      else {
        self.showToastService.showToast('danger', '发生错误', tip);
      }
    }).catch(error => {
      console.log(error);
      self.showToastService.showToast('danger', '发生错误', !!error.error && !!error.error.body ? error.error.body : error.message);
    })

  }


  //清缓存
  clearCache(self,url)
  {
    var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/" + self.server + url;
    self.http.get(api, {withCredentials: true}).subscribe(Response =>{
      
      if(Response && Response["code"]!=undefined)
        var code = Response["code"];
        if(code == 0) //成功
        {
          self.showToastService.showToast('success', '成功', "缓存清除成功!");
        }
        else  //失败
        {
          var msg = Response["msg"];
          self.showToastService.showToast('warning', '发生异常', msg);
          return;
        }
    },error => {
      console.log(error);
      self.showToastService.showToast('danger', '发生错误', !!error.error && !!error.error.body ? error.error.body : error.message);
    })
  }
}
