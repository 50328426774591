import { Injectable } from '@angular/core';
import { NbToastrService,NbComponentStatus,NbGlobalPhysicalPosition,} from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})

//显示弹窗警告 added by 葛明 2019-11-19
export class ShowToastService {

  constructor(private toastrService: NbToastrService) { }

  //显示弹窗警告
  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 8000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };

    this.toastrService.show(
      body,
      title,
      config);
  }
}
