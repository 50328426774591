<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><span>{{company}}&nbsp;&nbsp;&nbsp;&nbsp;{{title}}</span></a>
  </div>  
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout" hint="单击回车开始搜索" placeholder="全系统搜索" trim></nb-search>
    </nb-action>
    <!-- <nb-action class="control-item" icon="bell"  badgeStatus="danger" [badgeText]="badgeText" [nbPopover]="templateRef"  nbPopoverPlacement="bottom"></nb-action> -->
    
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture"
               nbContextMenuTag="my-context-menu">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<ng-template #templateRef>
  <div style="margin:5px;">
    <div  style="margin:5px;"  *ngIf="oldshow">
      <a  href="javascript:void(0)" (click)="goAlarm()" *ngIf="alarmNum==0?false:true">
        未处理的告警信息有{{alarmNum}}条
      </a>
      <div  href="javascript:void(0)" *ngIf="alarmNum==0?true:false">
        未处理的告警信息有{{alarmNum}}条
      </div>
    </div>
  </div>
</ng-template>