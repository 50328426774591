import { GeneralModuleFuncService } from './general-module-func.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ShowToastService } from './show-toast.service';


@Injectable({
  providedIn: 'root'
})
//权限检查 added by 葛明 2019-11-19
export class ChkPowerService {
  
  constructor(private generalModuleFuncService:GeneralModuleFuncService,  private showToastService: ShowToastService,public http:HttpClient,protected router: Router) { }

  //检查模块功能是否具有权限
  chkSonFunPower(moduleName,funName)
  {
    //debugger
    var totalModuleName = this.generalModuleFuncService.getTotalModuleNameByMenu(null, moduleName, this.router.routerState.snapshot.url);
    totalModuleName = totalModuleName.replaceAll('***','-'); //全名

    if(localStorage.getItem('manage-CurrentUserPowerItems'))
    {
      var parts = JSON.parse(localStorage.getItem('manage-CurrentUserPowerItems'));
      for (var i = 0; i < parts.length; i++) {
          var part = parts[i];
          if (part.indexOf( totalModuleName + '-' + funName) >= 0) {
              return true;
          }
      }
    }
    return false;
  }

  //检查是否登录
  chkLogin():Observable<boolean>
  {
    //debugger;
    let stream = new Observable<boolean>(observer =>{
      var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot4-platform2-system-server/chkLogin?random=" +  Math.random();
      this.http.get(api,{headers:{'X-Token':localStorage.getItem('manage-CurrentToken')},withCredentials: true,responseType: 'text'}).subscribe(Response =>{
        //console.log("response=" + Response.toString());
        if(Response && Response.toString().indexOf('successed') == 0)
        {
          localStorage.setItem('manage-CurrentUserName',Response.toString().split(',')[1]);  
          localStorage.setItem('manage-CurrentUserSex',Response.toString().split(',')[2]);        
          observer.next(true);
        }
        else {
          localStorage.removeItem('manage-CurrentUserName'); 
          localStorage.removeItem('manage-CurrentUserPowerItems'); 
          localStorage.removeItem('manage-CurrentUserSex'); 
          observer.next(false);
          //this.router.navigateByUrl("auth/login");
        }
      },error => {        
        console.log(error);
        localStorage.removeItem('manage-CurrentUserName'); 
        localStorage.removeItem('manage-CurrentUserPowerItems');  
        localStorage.removeItem('manage-CurrentUserSex'); 
        observer.next(false);
        //this.router.navigateByUrl("auth/login");
      })
    });
    return stream;    
  }

 //获得用户权限
 chkUserPower():Observable<boolean>
 {
   let stream = new Observable<boolean>(observer =>{
       var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot4-platform2-system-server/chkUserPower?random=" +  Math.random();
       this.http.get(api,{headers:{'X-Token':localStorage.getItem('manage-CurrentToken')},withCredentials: true,responseType: 'text'}).subscribe(Response =>{
         //console.log("response2=" + Response.toString());
         if(Response && Response.toString() != "decline")
         {
           localStorage.setItem('manage-CurrentUserPowerItems',Response.toString());  
           //this.router.navigateByUrl("pages/dashboard"); 
           observer.next(true);
         }
         else {
           localStorage.removeItem('manage-CurrentUserName'); 
           localStorage.removeItem('manage-CurrentUserPowerItems');   
           localStorage.removeItem('manage-CurrentUserSex'); 
           //this.router.navigateByUrl("auth/login");
           observer.next(false);
         }
       },error => {        
         console.log(error);
         localStorage.removeItem('manage-CurrentUserName'); 
         localStorage.removeItem('manage-CurrentUserPowerItems');  
         localStorage.removeItem('manage-CurrentUserSex'); 
         //this.router.navigateByUrl("auth/login");
         observer.next(false);
       })
     });
     return stream;
 }
 //获取海豚平台信息
 getDolphins():Observable<boolean>
 {
   let stream = new Observable<boolean>(observer =>{
       var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot4-platform2-park-server/dolphinFindAll?random=" +  Math.random();
       this.http.get(api,{withCredentials: true,params: {state: "有效"}}).subscribe(Response =>{
         //debugger;
         var data  = Response["data"];
         //data.splice(data.length-1,1); //删除 {'label': '未知', 'value': ''}
         let r = JSON.stringify(data);
        localStorage.setItem('dolphins',r);
        observer.next(true);         
       },error => {        
         console.log(error);
         localStorage.removeItem('dolphins');          
         observer.next(false);
       })
     });
     return stream;
 }

 //获取门禁权限信息
 getDoorPower():Observable<boolean>
 {
   let stream = new Observable<boolean>(observer =>{
       var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot4-platform2-system-server/getDoorPower?random=" +  Math.random();
       this.http.get(api,{headers:{'X-Token':localStorage.getItem('manage-CurrentToken')},withCredentials: true,responseType: 'text'}).subscribe(Response =>{
         //debugger;
        let r = !!Response ? Response : "";
        localStorage.setItem('getDoorPower',r);
        observer.next(true);         
       },error => {        
         console.log(error);
         localStorage.removeItem('getDoorPower');          
         observer.next(false);
       })
     });
     return stream;
 }

  //获取摄像头权限信息
  getCameraPower():Observable<boolean>
  {
    let stream = new Observable<boolean>(observer =>{
        var api = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot4-platform2-system-server/getCameraPower?random=" +  Math.random();
        this.http.get(api,{headers:{'X-Token':localStorage.getItem('manage-CurrentToken')},withCredentials: true,responseType: 'text'}).subscribe(Response =>{
          //debugger;
         let r = !!Response ? Response : "";
         localStorage.setItem('getCameraPower',r);
         observer.next(true);         
        },error => {        
          console.log(error);
          localStorage.removeItem('getCameraPower');          
          observer.next(false);
        })
      });
      return stream;
  }


   //登出
   loginOut()
   {
    //debugger
    var api = localStorage.getItem('protocol') + "://" +  localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot4-platform2-system-server/loginOut?random=" +  Math.random();
    this.http.get(api,{headers:{'X-Token':localStorage.getItem('manage-CurrentToken')},withCredentials: true,responseType: 'text',params:{applicationName:localStorage.getItem('platformBaseInfo-title')}}).subscribe(Response =>{
      if(Response && Response.toString() == "success")
      {
        localStorage.removeItem('manage-CurrentUserName'); 
        localStorage.removeItem('manage-CurrentUserPowerItems');  
        localStorage.removeItem('manage-CurrentUserSex'); 
        this.router.navigateByUrl("auth/login",{ replaceUrl: true });
      }
    },error => {        
      console.log(error);      
      this.showToastService.showToast('danger', '发生错误', !!error.error && !!error.error.body ? error.error.body :  error.message);
    })
   }

}
