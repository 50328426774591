import { Injectable } from '@angular/core';

import { DictionaryEntry } from '../interface/dictionary-entry';
import { FuncEntry } from '../interface/func-entry';

@Injectable({
  providedIn: 'root'
})

//将数据转换成树控件支持的格式 added by 葛明 2019-11-19
export class ParseToTreeDataService {

  constructor() { }

  //数组转换成树格式:DictionaryEntry 字典 type:数据集类型
  parseToDictionaryEntry(datas,type):DictionaryEntry[]{
    //debugger
    var result:DictionaryEntry[] = [];
    if(datas)
    {      
      //寻找顶级节点
      for (var i = 0; i < datas.length; i++) 
      {
        if(datas[i] && ((!!datas[i]["fatherId"] == false && !!datas[i]["moduleId"] == false && !!datas[i]["parentCode"] == false && !!datas[i]["parentIndexCode"] == false)||datas[i]["parentIndexCode"] == "-1"))
        {
          let fsentry:DictionaryEntry;
          if(type == "Dictionary" || type == "ModuleFunction") //字典表、模块功能表
          {
            fsentry = {name:datas[i]["name"],explain:datas[i]["explain"],
              state:datas[i]["state"],id:datas[i]["id"], fatherId:datas[i]["fatherId"],
              fatherName:datas[i]["fatherName"],kind:'doc',childEntries:[],expanded:false,
              value1:datas[i]["value1"],value2:datas[i]["value2"],value3:datas[i]["value3"],value4:datas[i]["value4"],
              standby1:datas[i]["standby1"],standby2:datas[i]["standby2"],standby3:datas[i]["standby3"],standby4:datas[i]["standby4"],standby5:datas[i]["standby5"],
              checked:datas[i]["checked"] || false, indeterminate:false
            };
          } 
          result.push(fsentry);
          delete datas[i];          
          //寻找儿子节点
          if(type == "Dictionary") //字典表
            this.findSonNode(datas,fsentry,"DictionaryEntry" + "-" + type);
          else if(type == "ModuleFunction") //模块功能表
            this.findSonNode(datas,fsentry,"DictionaryEntry" + "-" + type);
          
        }

      }        
    }
    return result;
  }  


  //寻找子节点，并把子节点push进自己的子节点数组中
  findSonNode(datas,fsentry,type){
    for(var i = 0; i < datas.length; i++)
    {
      if(datas[i] && (datas[i]["fatherId"] || datas[i]["moduleId"] || datas[i]["parentCode"]|| datas[i]["parentIndexCode"] ))
      {        
        if(datas[i]["fatherId"] == fsentry.id || (!!datas[i]["moduleId"] && datas[i]["moduleId"]  == fsentry.id) || (!!datas[i]["parentCode"] && datas[i]["parentCode"] == fsentry.code) || (!!datas[i]["parentIndexCode"] && datas[i]["parentIndexCode"]  == fsentry.indexCode))
        {
          fsentry.kind = 'dir';
          let sonfsentry;
          if(type == "DictionaryEntry-Dictionary" || type == "DictionaryEntry-ModuleFunction") //字典表转字典项目、模块功能表转字典项目
          {
            sonfsentry = {name:datas[i]["name"],explain:datas[i]["explain"],
              state:datas[i]["state"],id:datas[i]["id"], fatherId:datas[i]["fatherId"],
              fatherName:datas[i]["fatherName"],kind:'doc',childEntries:[],expanded:false,
              value1:datas[i]["value1"],value2:datas[i]["value2"],value3:datas[i]["value3"],value4:datas[i]["value4"],
              standby1:datas[i]["standby1"],standby2:datas[i]["standby2"],standby3:datas[i]["standby3"],standby4:datas[i]["standby4"],standby5:datas[i]["standby5"],
              checked:datas[i]["checked"] || false,indeterminate:false
            };
          }                    
          else if(type == "FuncEntry") //模块功能 带CheckBox
          {
             sonfsentry = {name:datas[i]["name"],kind:'doc',childEntries:[],expanded:false,id:datas[i]["id"],funId:datas[i]["funId"],totalName:datas[i]["totalName"],
            _parentId:datas[i]["fatherId"],checked:datas[i]["checked"] || false,indeterminate:false};
          }
          fsentry.childEntries.push(sonfsentry);
          delete datas[i];
          //寻找儿子节点
          this.findSonNode(datas,sonfsentry,type);
        }        
      }
    }
  }

  //数组转换成树格式:FuncEntry 模块功能,带CheckBox的形式，用在添加/修改角色时的权限数据源
  parseToFuncEntry(datas,needLoop:boolean):FuncEntry[]{
    //debugger
    var result:FuncEntry[] = [];
    if(datas)
    {      
      //寻找顶级节点      
      for (var i = datas.length - 1; i >=0 ; i--) 
      {
        if(datas[i] && !!datas[i]["fatherId"] == false)
        {
          let fsentry:FuncEntry = {name:datas[i]["name"],kind:'doc',childEntries:[],expanded:false,id:datas[i]["id"],funId:datas[i]["funId"],
          _parentId:datas[i]["fatherId"],checked:datas[i]["checked"] || false, indeterminate:false};
          if(result.length == 0 )
            result.push(fsentry);
          else{
            var lastId = Math.abs(result[result.length - 1].id);    //按照id的绝对值大小，从小到大排序        
            var thisId = Math.abs(fsentry.id);
            if(lastId < thisId)
              result.push(fsentry);
            else
              result.splice(0,0,fsentry);
          }

          delete datas[i];          
          //寻找儿子节点
          this.findSonNode(datas,fsentry,"FuncEntry");
        }
      }

      if(needLoop) //需要遍历树，来确定checkbox的状态
      {
        //遍历树，遍历checked，以确定indeterminate的状态
        for(var i = 0 ; i < result.length ; i++)
        {
          this.checkSonNode(result[i]);          
        }
      }  
    }
    return result;
  }

  //遍历所有节点，勾选指定的id
  checkNodeForChk(element,idstr):void
  {
    if(idstr.indexOf("," + element.id + ",") > -1)
    {
      element.checked = true;
    }
    if(element.childEntries.length > 0) //自己也是父节点
    {
      for(var j = 0 ; j < element.childEntries.length; j++)
      {
        this.checkNodeForChk(element.childEntries[j],idstr);
      }
    }
  }

  //遍历节点的子节点,并返回节点自身的状态；0：未勾选，1：不确定，2：勾选
  checkSonNode(fsentry):number
  {
    var result:number = 0;
    if(fsentry.childEntries.length > 0) //自己也是父节点
    {
      for(var j = 0 ; j < fsentry.childEntries.length; j++)
      {
        var r = this.checkSonNode(fsentry.childEntries[j]);
        if(r == 2) //子节点勾选了
        {
          if(j == 0 || result == 2) //是第一个子节点，或者之前的子节点也是勾选的
            result = 2;
          else
            result = 1;
        }
        else if(r == 0 ) //子节点没有勾选
        {
          if(result == 2 || result == 1) //之前的子节点全勾选了，或者部分勾选了
             result = 1;
          else
             result = 0;
        }
        else //子节点也是个父节点，且是部分勾选状态
        {
          result = 1;          
        }        

        // if(result == 1) //已经是部分勾选状态了，没必要继续遍历了，直接跳出循环
        //   break;
      }

      if(result == 1) //子节点部分勾选了，则自身为不确定状态
      {        
        fsentry.checked = false;
        fsentry.indeterminate = true;
      }
      else if(result == 2) //子节点全部勾选了，则自身也勾选
      {
        fsentry.checked = true;
        fsentry.indeterminate = false;
      }
      else
      {
        fsentry.checked = false;
        fsentry.indeterminate = false;
      }
    }
    else if(fsentry.checked){
      result = 2;
    } 
    return result;
  }

  //单击了CheckBox，处理单击后的树状态
  checkToggle(checked: boolean,row,sourceData)
  {
    //console.log(checked);
    //console.log(id);
    //debugger
    this.checkToggleSons(checked,row); //把子节点的勾选状态也同步改变
    row.data.checked = checked;
    row.data.indeterminate = false;
    
    //遍历树，遍历checked，以确定indeterminate的状态
    for(var i = 0 ; i < sourceData.length ; i++)
    {
      this.checkSonNode(sourceData[i]);          
    }
    //source = null;
    //source = this.dataSourceBuilder.create(sourceData, this.getters);
  }

  //把子节点的勾选状态也同步改变
  checkToggleSons(checked: boolean,row)
  {
    if(row.children)
    {
      for(var i = 0 ; i < row.children.length ; i++)
      {
        row.children[i].data.checked = checked;
        row.children[i].data.indeterminate = false;
        this.checkToggleSons(checked,row.children[i]); //把子节点的勾选状态也同步改变
      }      
    }    
  }

  //返回所有选中状态的叶子节点的id
  getLeafNodeIdsChk(sourceData)
  {
    var ids = "";
    var node;
    //遍历树，遍历checked，以确定indeterminate的状态
    for(var i = 0 ; i < sourceData.length ; i++)
    {
      node = sourceData[i];
      if(node.checked || node.indeterminate)
      {
        for(var j = 0 ; j < node.childEntries.length; j++)
        {
          ids = ids + this.getChildLeafNodeIdsChk(node.childEntries[j]) ;
        }
      }
    }

    if (ids.lastIndexOf(',') == ids.length -1)
      ids = ids.substring(0,ids.length - 1);

    return ids;
  }
  //返回所有选中状态的叶子节点的id（嵌套遍历）
  getChildLeafNodeIdsChk(fsentry):string
  {
    var ids = "";
    if(fsentry.childEntries.length > 0) //自己也是父节点
    {
      for(var j = 0 ; j < fsentry.childEntries.length; j++)
      {
        ids = ids + this.getChildLeafNodeIdsChk(fsentry.childEntries[j]);
      }
    }
    else{
      if(fsentry.checked && fsentry.id > 0)
      {
        ids = fsentry.id  + ",";
      }
    }
    return ids;
  }


  //返回所有选中状态的叶子节点的totalname
  getLeafNodeNamesChk(sourceData)
  {
    var names = "";
    var node;
    //遍历树，遍历checked，以确定indeterminate的状态
    for(var i = 0 ; i < sourceData.length ; i++)
    {
      node = sourceData[i];
      if(node.checked || node.indeterminate)
      {
        for(var j = 0 ; j < node.childEntries.length; j++)
        {
          names = names + this.getChildLeafNodeNamesChk(node.childEntries[j]) ;
        }
      }
    }

    if (names.lastIndexOf(',') == names.length -1)
      names = names.substring(0,names.length - 1);

    return names;
  }
  //返回所有选中状态的叶子节点的totalname（嵌套遍历）
  getChildLeafNodeNamesChk(fsentry):string
  {
    var names = "";
    if(fsentry.childEntries.length > 0) //自己也是父节点
    {
      for(var j = 0 ; j < fsentry.childEntries.length; j++)
      {
        names = names + this.getChildLeafNodeNamesChk(fsentry.childEntries[j]);
      }
    }
    else{
      if(fsentry.checked && fsentry.id > 0)
      {
        names = fsentry.totalName.replaceAll('***','-') + '-' + fsentry.name  + ",";
      }
    }
    return names;
  }
}
