import { Component } from '@angular/core';
import { DesktopService } from '../../../services/desktop.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed *ngIf="isShowHeader == 'show'">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  isShowHeader = 'show';

  constructor(private desktopService:DesktopService)
  {

  }

  ngOnInit(): void {
    this.desktopService.changeHeader.subscribe(isShow =>{
      //debugger;
      this.isShowHeader = isShow;
    })
  }
}

