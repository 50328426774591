import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { PieChart } from '../@core/data/earning';
import { TrafficBar } from '../@core/data/traffic-bar';
import { OrderProfitChartSummary } from '../@core/data/orders-profit-chart';
import { OrdersChart } from '../@core/data/orders-chart';
import { ProfitChart } from '../@core/data/profit-chart';
import { UserActive } from '../@core/data/user-activity';
import { FuncEntry } from '../interface/func-entry';
import { ParseToTreeDataService } from './parse-to-tree-data.service';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
//控制台后台服务类 added by 葛明 2020-2-25
export class DesktopService {
  changeHeader: Subject<string> = new Subject<string>();
  constructor(public http:HttpClient,protected router: Router,public parseToTreeDataService:ParseToTreeDataService) { }

  // //获取柱状图的数据: GF-单柱形图(有坐标)、GF-单柱形图(无坐标)、GF-环形柱形图
  // getBarDataByCurrentId(currentId,dataInterface): Observable<TrafficBar>
  // {
  //   //debugger;
  //   let stream = new Observable<TrafficBar>(observer =>{
  //     var api = dataInterface;//localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/getKeyVisitorStat";
  //     this.http.get(api,{withCredentials: true,params:{currentId:currentId}}).subscribe(Response =>{
  //       var datas:TrafficBar = {data: [],labels:[]};
  //       //debugger;
  //       if(Response instanceof Array)
  //       {
  //         Response.forEach(element => {
  //           datas.labels.push(element[0]);
  //           datas.data.push(element[1]);
  //         });
  //       }
  //       else
  //       {
  //         var r = (Response as any);
  //         if(r.labels)
  //           datas.labels = r.labels;

  //         if(r.values)
  //           datas.data = r.values;
  //       }
  //       observer.next(datas);
  //       observer.complete();
  //     },error => {
  //       console.log(error);
  //       observer.next(null);
  //       observer.complete();
  //     })
  //   });
  //   return stream;
  // }

  // //获取柱状图的数据: GF-单柱形图(有坐标)、GF-单柱形图(无坐标)
  // getBarDataByTypeAndCurrentId(type,currentId,dataInterface): Observable<TrafficBar>
  // {
  //   //debugger;
  //   let stream = new Observable<TrafficBar>(observer =>{
  //     var api = dataInterface;//localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/getPassagePersonBar";
  //     this.http.get(api,{withCredentials: true,params:{type:type,currentId:currentId}}).subscribe(Response =>{
  //       var datas:TrafficBar = {data: [],labels:[]};
  //       //debugger;
  //       if(Response instanceof Array)
  //       {
  //         Response.forEach(element => {
  //           datas.labels.push(element[0]);
  //           datas.data.push(element[1]);
  //         });
  //       }
  //       else
  //       {
  //         var r = (Response as any);
  //         if(r.labels)
  //           datas.labels = r.labels;

  //         if(r.values)
  //           datas.data = r.values;
  //       }
  //       observer.next(datas);
  //       observer.complete();
  //     },error => {
  //       console.log(error);
  //       observer.next(null);
  //       observer.complete();
  //     })
  //   });
  //   return stream;
  // }

  // //获取饼图数据:GF-统计饼图,JS-横向单柱形图，JS-多柱形图
  // getAnyDataByTypeAndCurrentId(type,currentId,dataInterface): Observable<any>
  // {
  //   //debugger;
  //   let stream = new Observable<any>(observer =>{
  //     var api = dataInterface ; //"http://171.35.109.45:8000"  + "/getEventConstitute";
  //     this.http.get<any>(api,{withCredentials: true,params:{type:type,currentId:currentId}}).subscribe(Response =>{
  //       observer.next(Response);
  //       observer.complete();
  //     },error => {
  //       console.log(error);
  //       observer.next(null);
  //       observer.complete();
  //     })
  //   });
  //   return stream;
  // }

  // //获取绘图柱形图的数据: GF-绘图柱形图,GF-数字概览,JS-横向单柱形图，JS-多柱形图
  // getAnyDataByCurrentId(currentId,dataInterface): Observable<any>
  // {
  //   //debugger;
  //   let stream = new Observable<any>(observer =>{
  //     var api = dataInterface;//localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/getKeyVisitorStat";
  //     this.http.get<any>(api,{withCredentials: true,params:{currentId:currentId}}).subscribe(Response =>{
  //       observer.next(Response);
  //       observer.complete();
  //     },error => {
  //       console.log(error);
  //       observer.next(null);
  //       observer.complete();
  //     })
  //   });
  //   return stream;
  // }

  //处理接口地址
  handleDataInterface(dataInterface)
  {
    if(dataInterface.indexOf("http") != 0 )
    {
      var addStr = localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort');
      if(dataInterface.substring(0,1) != "/")
      {
        addStr = addStr + "/";
      }
      dataInterface =  addStr + dataInterface;
    }

    if(dataInterface.indexOf("localStorage.getItem('platformBaseInfo-module')") != 0 ) //需要获取 localStorage.getItem('platformBaseInfo-module')
    {
      dataInterface = dataInterface.replace("localStorage.getItem('platformBaseInfo-module')",localStorage.getItem('platformBaseInfo-module'));
    }
    
    return dataInterface;

  }

  //获取数组数据: MT-多段落列表，MT-单行数字概览,GF-雷达图，
  getAnyData(dataInterface): Observable<any>
  {
    //debugger;
    dataInterface = this.handleDataInterface(dataInterface);
    let stream = new Observable<any>(observer =>{
      var api = dataInterface;//localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/visitorSearchListByAuto";
      this.http.get(api,{withCredentials: true}).subscribe(Response =>{
        observer.next(Response);
        observer.complete();
      },error => {
        console.log(error);
        observer.next(null);
        observer.complete();
      })
    });
    return stream;
  }

  //生成模块功能树列表,带checkbox 的情况  编辑\新增 角色信息时用到 nbTree  _idStr:已经选中的节点，needLoop:需要遍历节点
  getTreeDataEdit(dataInterface,_idStr,needLoop:boolean):Observable<FuncEntry[]>{
    dataInterface = this.handleDataInterface(dataInterface);

    let stream = new Observable<FuncEntry[]>(observer =>{
      var api = dataInterface;//localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/" + localStorage.getItem('gatewayServerName') + "/moduleFuncSearchTreeChkList?timeid=" +  Math.random();
      this.http.get(api,{withCredentials: true, params:
        {
          idStr:_idStr,
          moduleNames:localStorage.getItem('platformBaseInfo-module')
        }}).subscribe(Response =>{
        if(needLoop && !!_idStr == false) //如果要求遍历节点，但是却没有已选中的节点，则没必要再做遍历操作了
           needLoop = false;

        var moduleAndFunRows:FuncEntry[] = this.parseToTreeDataService.parseToFuncEntry(Response['rows'],needLoop);
        observer.next(moduleAndFunRows);
        observer.complete();
      },error => {
        console.log(error);
        observer.next(null);
        observer.complete();
      })
    });
    return stream;
  }

  //生成模块功能树列表,查看 角色信息时用到 nbTree
  getTreeDataView(dataInterface,_idStr):Observable<FuncEntry[]>{
    dataInterface = this.handleDataInterface(dataInterface);

    let stream = new Observable<FuncEntry[]>(observer =>{
      var api = dataInterface;//localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/" + localStorage.getItem('gatewayServerName') + "/moduleFuncSearchTreeListByIds?timeid=" +  Math.random();
      this.http.get(api,{withCredentials: true, params:
      {
        idStr:_idStr,    
        moduleNames:localStorage.getItem('platformBaseInfo-module')     
      }}).subscribe(Response =>{
        //debugger
        var moduleAndFunRows:FuncEntry[] = this.parseToTreeDataService.parseToFuncEntry(Response['rows'],false);
        observer.next(moduleAndFunRows);
      },error => {        
        console.log(error);
      })
    });
    return stream;    
  }

  //生成字典树列表,搜索用到，适用于ztree,isKeepLeaf:是否保留叶节点，idIsNullDfName：id 为空时的名称
  getAnyZtreeData(dataInterface,idIsNullDfName):Observable<any>{
    dataInterface = this.handleDataInterface(dataInterface);

    let stream = new Observable<any>(observer =>{
      var api = dataInterface;//localStorage.getItem('protocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/moduleFatherArray";
      this.http.get<any>(api,{withCredentials: true}).subscribe(Response =>{
        
        //debugger
        //   , params:
        // {
        //   name:topNodename,
        //   State:"有效",keepLeaf:isKeepLeaf
        // }
        var data = [];
        if (Response)
        {
          if(Response.data)
          {
            var array;
            if(Array.isArray(Response.data) == false)
            {
              array = JSON.parse(Response.data);
            }
            else
            {
              array = Response.data;
            } 
            data = this.handleDataForZtree(array,idIsNullDfName)
          }
          else
          {
            data = this.handleDataForZtree(Response,idIsNullDfName)
          }
          
        }
        observer.next(data);
        observer.complete();
      },error => {
        console.log(error);
        observer.next(null);
        observer.complete();
      })
    });
    return stream;
  }


  //对返回的数据集进行处理，以适用于ztree
  handleDataForZtree(Response,idIsNullDfName)
  {
    var data = Response ;
    data.push({'id':'','name':idIsNullDfName });
    return data;
  }

  //获取天气概况
  getWeather(type,city): Observable<object>
  {
    //debugger;
    let stream = new Observable<object>(observer =>{
      var api;
      if(type == 'weather')
        api = "https://free-api.heweather.net/s6/weather/now?location=" + city  + "&key=6dbe15e945944817bf3ca89466470b75";
      else
        api = "https://free-api.heweather.net/s6/air/now?location=" + city  + "&key=6dbe15e945944817bf3ca89466470b75";


      this.http.get<object>(api,{withCredentials: false}).subscribe(Response =>{

        observer.next(Response);
        observer.complete();
      },error => {
        console.log(error);
        observer.next(null);
        observer.complete();
      })
    });
    return stream;
  }

  //调用接口加载数据
  loadDataByInterface(_interface,timeType,currentId,category,alive,self,defaultData)
  {
    _interface = this.handleDataInterface(_interface);
    //debugger;
    if(!!timeType) //当前图表数据时段类型有值，则加上时段参数
    {
      if(_interface.indexOf("?") > 0)
        _interface = _interface + "&";
      else
        _interface = _interface + "?";

      _interface = _interface + "timeType=" + timeType;
    }

    if(!!category) //有类型参数
    {
      if(_interface.indexOf("?") > 0)
        _interface = _interface + "&";
      else
        _interface = _interface + "?";

      _interface = _interface + "type=" + category;
    }

    if(!!currentId || currentId == -1) //有选择子区域
    {
      if(_interface.indexOf("?") > 0)
        _interface = _interface + "&";
      else
        _interface = _interface + "?";

      _interface = _interface + "currentId=" + currentId;
    }
    
    
    this.getAnyData(_interface)
    .pipe(takeWhile(() => alive ))
    .subscribe(res => {
      //debugger;
      if(res)
      {
        if(res.data) //新接口返回的数据
        {
          self.data = res.data;
        }
        else
        {
          self.data = res;
        }

        //self.data = res;
      }
      else
      {
        self.data = defaultData;
      }      
    });
    
  }
}
