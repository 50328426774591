import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NbThemeService } from '@nebular/theme';
import { ShowToastService } from './show-toast.service';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
//wisdom 服务访问 added by 葛明 2020-7-14
export class VisitWisdomService {

  constructor(public showToastService: ShowToastService,private themeService: NbThemeService,@Inject(DOCUMENT) private _document: HTMLDocument,public http:HttpClient,protected router: Router) { }

  //获取平台基本信息
  getPlatformInfo(_name):Observable<boolean>
  {    
    //sessionStorage.clear();
    let stream = new Observable<boolean>(observer =>{
      var api = localStorage.getItem('wisdomProtocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/getPlatformInfo?random=" +  Math.random();
      this.http.get(api,{withCredentials: true,params:{name:_name}}).subscribe(Response =>{
        //console.log("response=" + Response.toString());
        //debugger;
        if(Response != null && Response != "null")
        {
          localStorage.setItem('platformBaseInfo-id',this.checkNull(Response['id']));
          localStorage.setItem('platformBaseInfo-name',this.checkNull(Response['name']));
          localStorage.setItem('platformBaseInfo-icon',this.checkNull(Response['icon']));        
          localStorage.setItem('platformBaseInfo-title',this.checkNull(Response['title']));
          localStorage.setItem('platformBaseInfo-company',this.checkNull(Response['company']));
          localStorage.setItem('platformBaseInfo-theme',this.checkNull(Response['theme']));
          localStorage.setItem('platformBaseInfo-dbName',this.checkNull(Response['dbName']));
          localStorage.setItem('platformBaseInfo-dbUser',this.checkNull(Response['dbUser']));
          localStorage.setItem('platformBaseInfo-dbPwd',this.checkNull(Response['dbPwd']));
          localStorage.setItem('platformBaseInfo-module',this.checkNull(Response['totalModuleName'])); //返回功能模块从顶级节点到本节点，完整的名称，中间用***分割
          localStorage.setItem('platformBaseInfo-remark',this.checkNull(Response['remark']));
          //added by 葛明 2022-6-24 
          //localStorage.setItem('gatewayServerName',this.checkNull(Response['typeConsumerServer']));
          localStorage.setItem('protocol',this.checkNull(Response['protocol']));
          localStorage.setItem('systemType',this.checkNull(Response['typeName']));
          
          if(Response['icon'])
            this._document.getElementById('appFavicon').setAttribute('href', './assets/ico/1616/' + Response['icon']);          
        
          if(Response['theme'])
            this.themeService.changeTheme(Response['theme']);

          if(Response['title'])
            this._document.title = Response['title'];
        
          if(Response['id'])
          {
            console.log("获取平台基本信息成功!");
            //this.showToastService.showToast('success', '获取成功', '获取平台基本信息成功!');
            // this.getPlatformModules(Response['id']).subscribe((value) =>{
            //   observer.next(true);              
            // });
            this.getFileServerHost(Response['id']).subscribe((value) =>{
              observer.next(true);              
            });
          }
          else
          {
            console.log("无法获取平台基本信息,ID为空!");
            observer.next(false);
          }
        }
        else
        {
          console.log("无法获取平台基本信息,请联系管理员!");
          //this.showToastService.showToast('danger', '发生错误', '无法获取平台基本信息,请联系管理员!');
          observer.next(false);
        }        
      },error => {        
        console.log("无法获取平台基本信息,发生错误!");
        console.log(error);
        //this.showToastService.showToast('danger', '发生错误', error);
        observer.next(false);
      })
    });
    return stream;   
  }

  //获取平台图片保存路径信息
  getFileServerHost(_platformId):Observable<boolean>
  {
    let stream = new Observable<boolean>(observer =>{
      var api = localStorage.getItem('wisdomProtocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/getFileServerHost?random=" +  Math.random();
      this.http.get<[]>(api,{withCredentials: true}).subscribe(Response =>{
        //console.log("response=" + Response.toString());
        //debugger;
        var data;
        if(Response != null )
        {
          data = !!Response["data"] ? Response["data"] : "" ;   
          localStorage.setItem('fileServerHost' ,data);

          if(!!data)
          {
            console.log("获取平台图片保存路径信息成功!");
            this.getDesktopInfo(_platformId).subscribe((value) =>{
              observer.next(true);              
            });           
          }
          else
          {
            console.log("没有平台图片保存路径信息!");
            this.getDesktopInfo(_platformId).subscribe((value) =>{
              observer.next(true);              
            });  
          }
        }
        else
        {
          console.log("无法获取平台图片保存路径信息,请联系管理员!");
          //this.showToastService.showToast('danger', '发生错误', '无法获取平台基本信息,请联系管理员!');
          observer.next(false);
        }        
      },error => {        
        console.log("无法获取平台图片保存路径信息,发生错误!");
        console.log(error);
        //this.showToastService.showToast('danger', '发生错误', error);
        observer.next(false);
      })
    });
    return stream; 
  }

  //获取平台桌面信息
  getDesktopInfo(_platformId):Observable<boolean>
  {
    let stream = new Observable<boolean>(observer =>{
      var api = localStorage.getItem('wisdomProtocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/getDesktopInfo?random=" +  Math.random();
      this.http.get<[]>(api,{withCredentials: true,params:{platformId:_platformId}}).subscribe(Response =>{
        //console.log("response=" + Response.toString());
        //debugger;
        if(Response != null )
        {
          
          if(Response.length > 0)
          {
            sessionStorage.setItem('desktopInfoList' ,JSON.stringify(Response));
            console.log("获取平台桌面信息成功!");
            this.getPlatformModules(_platformId).subscribe((value) =>{
              observer.next(true);              
            });
          }
          else
          {
            console.log("没有平台桌面信息!");
            this.getPlatformModules(_platformId).subscribe((value) =>{
              observer.next(true);              
            });
          }
        }
        else
        {
          console.log("无法获取平台桌面信息,请联系管理员!");
          //this.showToastService.showToast('danger', '发生错误', '无法获取平台基本信息,请联系管理员!');
          observer.next(false);
        }        
      },error => {        
        console.log("无法获取平台桌面信息,发生错误!");
        console.log(error);
        //this.showToastService.showToast('danger', '发生错误', error);
        observer.next(false);
      })
    });
    return stream; 
  }

  

  //获取平台功能模块信息
  getPlatformModules(_id):Observable<boolean>
  {
    let stream = new Observable<boolean>(observer =>{
      var api = localStorage.getItem('wisdomProtocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/getPlatformModules?random=" +  Math.random();
      this.http.get<[]>(api,{withCredentials: true,params:{platformId:_id}}).subscribe(Response =>{
        //console.log("response=" + Response.toString());
        //debugger;        
        if(Response != null )
        {
          console.log("获取平台功能模块信息成功!");
          var callFuncs = [];
          Response.forEach(element => {
            callFuncs.push(this.getPlatformModuleColumns(element));          
          })
          forkJoin(
            callFuncs
          )
          .subscribe(() => {
            observer.next(true);
          }); 
          //this.showToastService.showToast('success', '获取成功', '获取平台功能模块信息成功!');    
        }
        else
        {
          console.log("无法获取平台功能模块信息,请联系管理员!");
          observer.next(false);
          //this.showToastService.showToast('danger', '发生错误', '无法获取平台功能模块信息,请联系管理员!');
        }      
      },error => {        
        console.log("无法获取平台功能模块信息,发生错误!");
        console.log(error);
        observer.next(false);
        //this.showToastService.showToast('danger', '发生错误', error);
      })
    });
    return stream; 
  }

  //根据平台相关的功能模块id获取该功能模块相关的字段列表信息
  getPlatformModuleColumns(element):Observable<boolean>
  {
    let stream = new Observable<boolean>(observer =>{
      var api = localStorage.getItem('wisdomProtocol') + "://" + localStorage.getItem('dominName') + ":" + localStorage.getItem('gatewayServerPort') + "/iot3-wisdom-consumer/getPlatformModuleColumns?random=" +  Math.random();
      this.http.get<[]>(api,{withCredentials: true,params:{moduleId:element['id']}}).subscribe(Response =>{
        //console.log("response=" + Response.toString());
        //debugger;
        if(Response != null )
        {
          element['columns'] = Response;
          var str = JSON.stringify(element);          
          sessionStorage.setItem('platformModules-' + element['totalName'],str);
          //console.log("获取平台功能模块:" +　element['dicName']　+　"  的字段信息成功!");
          observer.next(true);
          observer.complete();
        }
        else
        {
          console.log("获取平台功能模块:" + element['totalName'] + "  的字段信息失败,请联系管理员!");
          observer.next(false);
          observer.complete();
          //this.showToastService.showToast('danger', '发生错误', '无法获取平台功能模块的字段信息,请联系管理员!');
        }      
      },error => {        
        console.log("获取平台功能模块:" + element['totalName'] + "  的字段信息失败,发生错误!");
        console.log(error);
        observer.next(false);
        observer.complete();
        //this.showToastService.showToast('danger', '发生错误', error);
      })
    });
    return stream; 
  }

  //判断内容是否为null
  checkNull(str):string
  {
    if(!!str == false)
    {
      return "";
    }
    else
    {
      return str;
    }
  }
}
